// src/components/LeaderboardSection.js

import React from 'react';
import './LeaderboardSection.css';
import { Container, Table } from 'react-bootstrap';

const leaderboardData = [
  { no: 1, name: 'George', ranking: 25, category: 'AI', image: '/images/mock.png' },
  { no: 2, name: 'Max', ranking: 20, category: 'Development', image: '/images/mock.png' },
  { no: 3, name: 'Faizan', ranking: 19, category: 'UI/UX', image: '/images/mock.png' },
  { no: 4, name: 'Wajaaz', ranking: 16, category: 'AI', image: '/images/mock.png' },
  { no: 5, name: 'John', ranking: 10, category: 'UI/UX', image: '/images/mock.png' },
  { no: 6, name: 'George', ranking: 12, category: 'Mathematics', image: '/images/mock.png' },
  { no: 7, name: 'Kerry', ranking: 11, category: 'AI', image: '/images/mock.png' },
  { no: 8, name: 'John', ranking: 10, category: 'UI/UX', image: '/images/mock.png' },
  { no: 9, name: 'George', ranking: 5, category: 'AI', image: '/images/mock.png' },
  { no: 10, name: 'David', ranking: 2, category: 'AI', image: '/images/mock.png' }
];

const LeaderboardSection = () => {
  return (
    <div className="leaderboard-section">
      <Container>
        <h2 className="leaderboard-title">Leader Board</h2>
        <p className="leaderboard-subtitle">Our top students performing on OASEE</p>
        <Table bordered hover responsive className="leaderboard-table">
          <thead>
            <tr>
              <th>No</th>
              <th>Name</th>
              <th>Ranking</th>
              <th>Category</th>
            </tr>
          </thead>
          <tbody>
            {leaderboardData.map((item, index) => (
              <tr key={index}>
                <td>{item.no}</td>
                <td>
                  <img src={item.image} alt={item.name} className="profile-image" />
                  {item.name}
                </td>
                <td>{item.ranking}</td>
                <td>{item.category}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </div>
  );
};

export default LeaderboardSection;
