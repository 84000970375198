// src/firebase.js

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyADnxGQbQ3jdfYZKiHyU9WG-CTtq0h6eBo",
    authDomain: "oasee-study-smart.firebaseapp.com",
    projectId: "oasee-study-smart",
    storageBucket: "oasee-study-smart.appspot.com",
    messagingSenderId: "947616234201",
    appId: "1:947616234201:web:b36251cd9c7b2983e51cc8",
    measurementId: "G-63VPKS7QY6"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app); 
const functions = getFunctions(app);

export { db, auth, storage, functions };
