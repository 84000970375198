import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase'; // Adjust the path to your firebase config
import './HeroContent.css'; // Import your CSS file

const LoginContent = () => {
  const [enContent, setEnContent] = useState({});
  const [deContent, setDeContent] = useState({});
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const docRef = doc(db, 'translations', 'Login');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();

          // Parse the JSON string into objects
          setEnContent(JSON.parse(data.en) || {});
          setDeContent(JSON.parse(data.de) || {});
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchContent();
  }, []);

  const handleEnChange = (e) => {
    const { name, value } = e.target;
    setEnContent({
      ...enContent,
      [name]: value,
    });
  };

  const handleDeChange = (e) => {
    const { name, value } = e.target;
    setDeContent({
      ...deContent,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);

    try {
      const docRef = doc(db, 'translations', 'Login');

      // Convert the content back into JSON strings before saving
      await setDoc(docRef, {
        en: JSON.stringify(enContent),
        de: JSON.stringify(deContent)
      }, { merge: true });

      alert('Content updated successfully!');
    } catch (error) {
      console.error('Error saving document:', error);
      alert('Failed to save content. Please try again.');
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <Container>
        <Spinner animation="border" />
        <span>Loading content...</span>
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <Col>
          <h2>Login Page Content Management</h2>
          <p>Edit the content for the Login page in both English and German.</p>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <h3>English Content</h3>
            {Object.entries(enContent).map(([label, value]) => (
              <Form.Group controlId={`en-${label}`} key={label}>
                <Form.Label>{label}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name={label}
                  value={value}
                  onChange={handleEnChange}
                  className="custom-textarea"  // Apply custom class
                />
              </Form.Group>
            ))}
          </Col>
          <Col md={6}>
            <h3>German Content</h3>
            {Object.entries(deContent).map(([label, value]) => (
              <Form.Group controlId={`de-${label}`} key={label}>
                <Form.Label>{label}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name={label}
                  value={value}
                  onChange={handleDeChange}
                  className="custom-textarea"  // Apply custom class
                />
              </Form.Group>
            ))}
          </Col>
        </Row>
        <Row>
          <Col>
            <Button variant="primary" type="submit" disabled={saving}>
              {saving ? 'Saving...' : 'Save Changes'}
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default LoginContent;
