import React, { useState, useEffect } from 'react';
import './PortfolioSection.css';
import { Container, Row, Col } from 'react-bootstrap';
import { doc, getDoc } from "firebase/firestore"; // Import Firestore methods
import { db } from '../firebase'; // Ensure this path is correct

const PortfolioSection = ({ language }) => {
  const [translatedTexts, setTranslatedTexts] = useState(null); // Initialize to null

  // Fetch translations from Firestore based on the selected language
  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        const docRef = doc(db, 'translations', 'PortfolioSection');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const translationData = JSON.parse(data[language]); // Parse the string to JSON
          setTranslatedTexts(translationData); // Update the state with parsed data
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      }
    };

    fetchTranslations();
  }, [language]);

  return (
    <div className="portfolio-section">
      <Container>
        {translatedTexts && (
          <>
            <h2 className="portfolio-title">{translatedTexts.title}</h2>
            <p className="portfolio-subtitle">{translatedTexts.subtitle}</p>
            <Row className="metrics-row">
              {translatedTexts.metrics.map((metric, index) => (
                <Col key={index} xs={12} sm={6} md={4} lg={2} className="metric-col">
                  <h3 className="metric-value">{metric.value}</h3>
                  <p className="metric-label">{metric.label}</p>
                </Col>
              ))}
            </Row>
          </>
        )}
      </Container>
    </div>
  );
};

export default PortfolioSection;
