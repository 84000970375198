import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase'; // Adjust the path to your firebase config
import './HeroContent.css'; // Import your CSS file

const HomePortfolioContent = () => {
  const [enContent, setEnContent] = useState({});
  const [deContent, setDeContent] = useState({});
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const docRef = doc(db, 'translations', 'PortfolioSection');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();

          // Parse the JSON string into objects
          setEnContent(JSON.parse(data.en) || {});
          setDeContent(JSON.parse(data.de) || {});
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchContent();
  }, []);

  const handleEnChange = (e) => {
    const { name, value } = e.target;
    setEnContent({
      ...enContent,
      [name]: value,
    });
  };

  const handleDeChange = (e) => {
    const { name, value } = e.target;
    setDeContent({
      ...deContent,
      [name]: value,
    });
  };

  const handleMetricChange = (content, setContent, lang, index, field, newValue) => {
    const updatedMetrics = content.metrics.map((metric, i) =>
      i === index ? { ...metric, [field]: newValue } : metric
    );
    setContent({
      ...content,
      metrics: updatedMetrics,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);

    try {
      const docRef = doc(db, 'translations', 'PortfolioSection');

      // Convert the content back into JSON strings before saving
      await setDoc(docRef, {
        en: JSON.stringify(enContent),
        de: JSON.stringify(deContent)
      }, { merge: true });

      alert('Content updated successfully!');
    } catch (error) {
      console.error('Error saving document:', error);
      alert('Failed to save content. Please try again.');
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <Container>
        <Spinner animation="border" />
        <span>Loading content...</span>
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <Col>
          <h2>Portfolio Section Content Management</h2>
          <p>Edit the content for the Portfolio section in both English and German.</p>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <h3>English Content</h3>
            <Form.Group controlId="en-title">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={enContent.title || ''}
                onChange={handleEnChange}
              />
            </Form.Group>
            <Form.Group controlId="en-subtitle">
              <Form.Label>Subtitle</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="subtitle"
                value={enContent.subtitle || ''}
                onChange={handleEnChange}
                className="custom-textarea"
              />
            </Form.Group>
            <h4>Metrics</h4>
            {enContent.metrics?.map((metric, index) => (
              <Row key={index}>
                <Col>
                  <Form.Group controlId={`en-metric-value-${index}`}>
                    <Form.Label>Value</Form.Label>
                    <Form.Control
                      type="text"
                      value={metric.value}
                      onChange={(e) =>
                        handleMetricChange(enContent, setEnContent, 'en', index, 'value', e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId={`en-metric-label-${index}`}>
                    <Form.Label>Label</Form.Label>
                    <Form.Control
                      type="text"
                      value={metric.label}
                      onChange={(e) =>
                        handleMetricChange(enContent, setEnContent, 'en', index, 'label', e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
            ))}
          </Col>
          <Col md={6}>
            <h3>German Content</h3>
            <Form.Group controlId="de-title">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={deContent.title || ''}
                onChange={handleDeChange}
              />
            </Form.Group>
            <Form.Group controlId="de-subtitle">
              <Form.Label>Subtitle</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="subtitle"
                value={deContent.subtitle || ''}
                onChange={handleDeChange}
                className="custom-textarea"
              />
            </Form.Group>
            <h4>Metrics</h4>
            {deContent.metrics?.map((metric, index) => (
              <Row key={index}>
                <Col>
                  <Form.Group controlId={`de-metric-value-${index}`}>
                    <Form.Label>Value</Form.Label>
                    <Form.Control
                      type="text"
                      value={metric.value}
                      onChange={(e) =>
                        handleMetricChange(deContent, setDeContent, 'de', index, 'value', e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId={`de-metric-label-${index}`}>
                    <Form.Label>Label</Form.Label>
                    <Form.Control
                      type="text"
                      value={metric.label}
                      onChange={(e) =>
                        handleMetricChange(deContent, setDeContent, 'de', index, 'label', e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
        <Row>
          <Col>
            <Button variant="primary" type="submit" disabled={saving}>
              {saving ? 'Saving...' : 'Save Changes'}
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default HomePortfolioContent;
