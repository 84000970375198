import React, { useState, useEffect } from 'react';
import './Contact.css';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { doc, getDoc } from "firebase/firestore"; // Import Firestore methods
import { db } from '../firebase'; // Ensure this path is correct

const Contact = ({ language }) => {
  const [translatedTexts, setTranslatedTexts] = useState(null); // Start with null

  // Fetch translations from Firestore based on the selected language
  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        const docRef = doc(db, 'translations', 'Contact');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const translationData = JSON.parse(data[language]); // Parse the string to JSON
          setTranslatedTexts(translationData); // Update state with the parsed data
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      }
    };

    fetchTranslations();
  }, [language]);

  return (
    <div className="contact-page">
      <div className="contact-cover">
        <Container>
          {translatedTexts && <h1 className="contact-heading">{translatedTexts.contactHeading}</h1>}
        </Container>
      </div>

      <Container>
        <div className="content-spacing"></div>
        
        <Row className="mb-5">
          <Col md={6}>
            <img src="/images/contact_page.png" alt="Contact" className="img-fluid contact-image" />
          </Col>
          <Col md={6} className="contact-info">
            {translatedTexts && (
              <>
                <h5 className="text-center">{translatedTexts.feelFreeText}</h5>
                <ul className="contact-details text-center">
                  <li><strong>{translatedTexts.phoneLabel}</strong></li>
                  <li><strong>{translatedTexts.emailInfoLabel}</strong></li>
                  <li><strong>{translatedTexts.addressLabel}</strong></li>
                </ul>
                <h2 className="text-center">{translatedTexts.followUs}</h2>
                <div className="social-icons text-center">
                  <a href="https://www.facebook.com/profile.php?id=61558257116233" target="_blank" rel="noopener noreferrer" className="facebook">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="https://www.instagram.com/oasee-ch" target="_blank" rel="noopener noreferrer" className="instagram">
                    <i className="bi bi-instagram"></i>
                  </a>
                  <a href="https://www.linkedin.com/company/oasee-ch" target="_blank" rel="noopener noreferrer" className="linkedin">
                    <i className="bi bi-linkedin"></i>
                  </a>
                </div>
              </>
            )}
          </Col>
        </Row>

        <Row className="mb-5">
          <Col md={12}>
            {translatedTexts && <h3>{translatedTexts.getInTouch}</h3>}
          </Col>
          <Col md={6}>
            <Form>
              <Row>
                <Col md={12}>
                  <Form.Group controlId="formName" className="mb-3">
                    {translatedTexts && <Form.Label>{translatedTexts.nameLabel}</Form.Label>}
                    <Form.Control type="text" placeholder={translatedTexts ? translatedTexts.nameLabel : ''} />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="formEmail" className="mb-3">
                    {translatedTexts && <Form.Label>{translatedTexts.emailLabel}</Form.Label>}
                    <Form.Control type="email" placeholder={translatedTexts ? translatedTexts.emailLabel : ''} />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group controlId="formSubject" className="mb-3">
                {translatedTexts && <Form.Label>{translatedTexts.subjectLabel}</Form.Label>}
                <Form.Control type="text" placeholder={translatedTexts ? translatedTexts.subjectLabel : ''} />
              </Form.Group>
              <Form.Group controlId="formMessage" className="mb-3">
                {translatedTexts && <Form.Label>{translatedTexts.messageLabel}</Form.Label>}
                <Form.Control as="textarea" rows={4} placeholder={translatedTexts ? translatedTexts.messageLabel : ''} />
              </Form.Group>
              <Button variant="primary" type="submit">
                {translatedTexts ? translatedTexts.sendMessageButton : 'Send'}
              </Button>
            </Form>
          </Col>
          <Col md={6}>
            <div className="map-container">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2757.920545156983!2d8.045600315640063!3d47.39436087916862!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47903be5b98fffff%3A0x6c106b1958a92d1!2sLaurenzenvorstadt%2047%2C%205000%20Aarau%2C%20Switzerland!5e0!3m2!1sen!2sus!4v1693915022999!5m2!1sen!2sus"
                width="100%"
                height="380"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                title="Oasee Location"
              ></iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
