import React, { useState, useEffect } from 'react';
import './ProfileSettings.css';
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { auth, db, storage } from '../firebase'; // Ensure the correct paths to firebase.js
import enTranslations from '../translations/en.json';
import deTranslations from '../translations/de.json';

const ProfileSettings = ({ language }) => {
  const [loading, setLoading] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState('');
  const [fullName, setFullName] = useState('');
  const [headline, setHeadline] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [phoneCountryCode, setPhoneCountryCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const translations = language === 'de' ? deTranslations.profileSettings : enTranslations.profileSettings;

  useEffect(() => {
    const fetchUserProfile = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, "students", user.uid));
        if (userDoc.exists()) {
          const data = userDoc.data();
          setProfileImageUrl(data.ProfilePicture || '');
          setFullName(data.name || '');
          setHeadline(data.Headline || '');
          setCity(data.City || '');
          setCountry(data.Country || '');
          setPhoneCountryCode(data.PhoneCountryCode || '');
          setPhoneNumber(data.PhoneNumber || '');
        }
      }
    };
    fetchUserProfile();
  }, []);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setProfileImage(file);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    const user = auth.currentUser;

    let imageUrl = profileImageUrl;

    if (profileImage) {
      const storageRef = ref(storage, `profileImages/${user.uid}`);
      const uploadTask = uploadBytesResumable(storageRef, profileImage);

      await new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          null,
          (error) => {
            console.error('Upload error:', error);
            reject(error);
          },
          async () => {
            imageUrl = await getDownloadURL(uploadTask.snapshot.ref);
            setProfileImageUrl(imageUrl);
            resolve();
          }
        );
      });
    }

    const userRef = doc(db, "students", user.uid);
    await updateDoc(userRef, {
      ProfilePicture: imageUrl,
      name: fullName,
      Headline: headline,
      City: city,
      Country: country,
      PhoneCountryCode: phoneCountryCode,
      PhoneNumber: phoneNumber,
    });

    setLoading(false);
    alert(translations.profileUpdated);
  };

  return (
    <div className="profile-settings-page">
      <Container>
        <Row className="justify-content-center">
          <Col md={8}>
            <h2>{translations.profileSettings}</h2>
            <Form onSubmit={handleSave}>
              <Form.Group controlId="formProfileImage" className="mb-3">
                <Form.Label>{translations.profileImage}</Form.Label>
                <div>
                  {profileImageUrl && <img src={profileImageUrl} alt="Profile" className="img-fluid mb-3 profile-image-preview" />}
                  <Form.Control type="file" onChange={handleImageUpload} />
                </div>
              </Form.Group>
              <Form.Group controlId="formFullName" className="mb-3">
                <Form.Label>{translations.fullName}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={translations.fullName}
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formHeadline" className="mb-3">
                <Form.Label>{translations.headline}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={translations.headline}
                  value={headline}
                  onChange={(e) => setHeadline(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="formCity" className="mb-3">
                <Form.Label>{translations.city}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={translations.city}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="formCountry" className="mb-3">
                <Form.Label>{translations.country}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={translations.country}
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="formPhoneCountryCode" className="mb-3">
                <Form.Label>{translations.phoneCountryCode}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={translations.phoneCountryCode}
                  value={phoneCountryCode}
                  onChange={(e) => setPhoneCountryCode(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="formPhoneNumber" className="mb-3">
                <Form.Label>{translations.phoneNumber}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={translations.phoneNumber}
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Form.Group>
              <Button variant="primary" type="submit" disabled={loading}>
                {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : translations.saveChanges}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProfileSettings;
