import React, { useState, useEffect } from 'react';
import './Categories.css';
import { Container, Table, Button, Modal, Form } from 'react-bootstrap';
import { db } from '../../firebase';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import 'bootstrap-icons/font/bootstrap-icons.css'; // Make sure you have imported bootstrap icons

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [icon, setIcon] = useState('bi-briefcase'); // Default icon
  const [bgColor, setBgColor] = useState('bg-light-purple'); // Default background color

  const iconOptions = [
    'bi-alarm', 'bi-basket', 'bi-bell', 'bi-book', 'bi-camera',
    'bi-cpu', 'bi-diagram-3', 'bi-folder', 'bi-gem', 'bi-globe',
    'bi-heart', 'bi-laptop', 'bi-lightbulb', 'bi-music-note',
    'bi-palette', 'bi-pencil', 'bi-person', 'bi-puzzle', 'bi-tools',
    'bi-briefcase', 'bi-calendar', 'bi-cart', 'bi-cloud', 'bi-gear',
    'bi-house', 'bi-key', 'bi-map', 'bi-microphone', 'bi-printer'
    // Add more icons here as needed
  ];

  const bgColorOptions = [
    'bg-light-purple', 'bg-light-green', 'bg-light-orange',
    'bg-light-red', 'bg-light-grey'
  ];

  useEffect(() => {
    const fetchCategories = async () => {
      const querySnapshot = await getDocs(collection(db, 'categories'));
      const categoriesData = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id
      }));
      setCategories(categoriesData);
    };

    fetchCategories();
  }, []);

  const handleShowModal = (category = null) => {
    setCurrentCategory(category);
    setIcon(category ? category.icon : 'bi-briefcase');
    setBgColor(category ? category.bgColor : 'bg-light-purple');
    setEditMode(!!category);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentCategory(null);
    setEditMode(false);
  };

  const handleSaveCategory = async () => {
    const categoryData = {
      name: currentCategory.name,
      bgColor,
      icon
    };

    if (editMode) {
      await updateDoc(doc(db, 'categories', currentCategory.id), categoryData);
      setCategories(categories.map(category => category.id === currentCategory.id ? { ...category, ...categoryData } : category));
    } else {
      const docRef = await addDoc(collection(db, 'categories'), categoryData);
      setCategories([...categories, { id: docRef.id, ...categoryData }]);
    }

    setShowModal(false);
  };

  const handleDeleteCategory = async (id) => {
    await deleteDoc(doc(db, 'categories', id));
    setCategories(categories.filter(category => category.id !== id));
  };

  return (
    <Container className="mt-4">
      <h2>Categories</h2>
      <Button onClick={() => handleShowModal()}>Add New Category</Button>
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>Name</th>
            <th>Icon</th>
            <th>Background Color</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category) => (
            <tr key={category.id}>
              <td>{category.name}</td>
              <td><i className={`bi ${category.icon}`}></i></td>
              <td>{category.bgColor}</td>
              <td>
                <Button variant="warning" onClick={() => handleShowModal(category)}>Edit</Button>{' '}
                <Button variant="danger" onClick={() => handleDeleteCategory(category.id)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{editMode ? 'Edit Category' : 'Add New Category'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formCategoryName">
              <Form.Label>Category Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Mathematics"
                value={currentCategory?.name || ''}
                onChange={(e) => setCurrentCategory({ ...currentCategory, name: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="formCategoryBgColor">
              <Form.Label>Background Color</Form.Label>
              <Form.Control as="select" value={bgColor} onChange={(e) => setBgColor(e.target.value)}>
                {bgColorOptions.map((colorOption) => (
                  <option key={colorOption} value={colorOption}>
                    {colorOption}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formCategoryIcon">
              <Form.Label>Icon</Form.Label>
              <Form.Control as="select" value={icon} onChange={(e) => setIcon(e.target.value)}>
                {iconOptions.map((iconOption) => (
                  <option key={iconOption} value={iconOption}>
                    <i className={`bi ${iconOption}`}></i> {iconOption}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
          <Button variant="primary" onClick={handleSaveCategory}>{editMode ? 'Update' : 'Save'}</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Categories;
