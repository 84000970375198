// src/superadmin/pages/DashboardLayout.js

import React from 'react';
import Sidebar from '../components/Sidebar';
import { Route, Routes } from 'react-router-dom';
import DashboardMain from './DashboardMain';
import Categories from './Categories';
import './DashboardLayout.css';
import Courses from './CourseManagement';
import AddCourse from './AddCourse';
import Consultations from './Consultations';
import Users from './Users';
import AboutContent from './AboutContent';
import HeroContent from './HeroContent';
import BookingContent from './BookingContent';
import ContactContent from './ContactContent';
import FooterContent from './FooterContent';
import HomePortfolioContent from './HomePortfolioContent';
import LoginContent from './LoginContent';
import RegisterContent from './RegisterContent';
import HeaderContent from './HeaderContent';

const DashboardLayout = () => {
  return (
    <div className="dashboard-layout">
      <Sidebar />
      <div className="dashboard-content">
        <Routes>
          <Route path="/" element={<DashboardMain />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/course-management" element={<Courses />} />
          <Route path="/add-course" element={<AddCourse />} />
          <Route path="/consultations" element={<Consultations />} />
          <Route path="/users" element={<Users />} />
          <Route path="/about-content" element={<AboutContent />} />
          <Route path="/hero-content" element={<HeroContent />} />
          <Route path="/booking-content" element={<BookingContent />} />
          <Route path="/contact-content" element={<ContactContent />} />
          <Route path="/footer-content" element={<FooterContent />} />
          <Route path="/port-content" element={<HomePortfolioContent />} />
          <Route path="/login-content" element={<LoginContent />} />
          <Route path="/register-content" element={<RegisterContent />} />
          <Route path="/header-content" element={<HeaderContent />} />
        </Routes>
      </div>
    </div>
  );
};

export default DashboardLayout;
