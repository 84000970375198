import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Row, Col, ListGroup, Spinner, ProgressBar } from 'react-bootstrap';
import { auth, db } from '../firebase';
import { doc, getDoc, collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import './MyCourseLearning.css';
import translateText from '../utils/translate';

const MyCourseLearning = ({ language }) => {
  const { courseId } = useParams();
  const navigate = useNavigate();
  const [course, setCourse] = useState(null);
  const [courseContent, setCourseContent] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [lessonProgress, setLessonProgress] = useState({});

  const translatedTexts = useMemo(() => ({
    courseTitle: 'Course Title',
    myProgress: 'My Progress',
    lessonTitle: 'Lesson',
    lessonDetails: 'Details',
    selectLesson: 'Select a lesson to view the content.',
    noContent: 'No valid content available for this lesson.',
    joinZoom: 'Join Zoom Meeting',
    joinGoogleMeet: 'Join Google Meet',
    viewPDF: 'View PDF',
    openLink: 'Open Link',
  }), []);

  const [translatedTextsState, setTranslatedTextsState] = useState(translatedTexts);

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          navigate('/login');
          return;
        }

        const enrollmentDocRef = doc(db, 'enrolledcourses', `${user.uid}_${courseId}`);
        const enrollmentDoc = await getDoc(enrollmentDocRef);
        if (!enrollmentDoc.exists()) {
          navigate('/my-courses');
          return;
        }

        const courseDoc = await getDoc(doc(db, 'courses', courseId));
        if (courseDoc.exists()) {
          setCourse(courseDoc.data());
        } else {
          console.error('Course not found!');
          navigate('/my-courses');
          return;
        }

        const contentQuery = query(collection(db, 'courseContent'), where('CourseId', '==', courseId));
        const contentSnapshot = await getDocs(contentQuery);
        const contentList = contentSnapshot.docs.map(doc => doc.data());

        contentList.sort((a, b) => a.LessonNumber - b.LessonNumber);

        setCourseContent(contentList);
        if (contentList.length > 0) {
          setSelectedLesson(contentList[0]);
        }

        const initialProgress = enrollmentDoc.data().Progress || 0;
        setProgress(initialProgress);

        const progressData = enrollmentDoc.data();
        const lessonProgressData = {};
        contentList.forEach((lesson) => {
          const lessonKey = `Lesson${lesson.LessonNumber}_Progress`;
          lessonProgressData[lessonKey] = progressData[lessonKey] || 0;
        });
        setLessonProgress(lessonProgressData);

        setLoading(false);
      } catch (error) {
        console.error('Error fetching course data:', error);
        navigate('/my-courses');
      }
    };

    fetchCourseData();
  }, [courseId, navigate]);

  useEffect(() => {
    const translateContent = async () => {
      if (course) {
        const translatedTitle = await translateText(course.Title, language);
        setCourse((prev) => ({ ...prev, Title: translatedTitle }));
      }

      if (courseContent.length > 0) {
        const translatedContent = await Promise.all(
          courseContent.map(async (lesson) => {
            const translatedTitle = await translateText(lesson.Title, language);
            const translatedDetails = await translateText(lesson.Details, language);
            return { ...lesson, Title: translatedTitle, Details: translatedDetails };
          })
        );
        setCourseContent(translatedContent);
      }

      const newTranslatedTexts = {};
      for (const [key, value] of Object.entries(translatedTexts)) {
        newTranslatedTexts[key] = await translateText(value, language);
      }
      setTranslatedTextsState(newTranslatedTexts);
    };

    translateContent();
  }, [course, courseContent, language, translatedTexts]);

  const handleLessonClick = (lesson) => {
    setSelectedLesson(lesson);

    // Handle progress update for non-video lessons
    if (lesson.URL && !lesson.URL.includes('.mp4') && !lesson.URL.includes('.pdf')) {
      updateLessonProgress(lesson.LessonNumber, 100);
    }
  };

  const updateLessonProgress = async (lessonNumber, newProgress) => {
    const lessonKey = `Lesson${lessonNumber}_Progress`;
    if (newProgress > (lessonProgress[lessonKey] || 0)) {
      setLessonProgress((prev) => ({
        ...prev,
        [lessonKey]: newProgress,
      }));

      const user = auth.currentUser;
      const enrollmentDocRef = doc(db, 'enrolledcourses', `${user.uid}_${courseId}`);
      const overallProgress = Math.min(100, progress + ((newProgress - (lessonProgress[lessonKey] || 0)) / courseContent.length));
      setProgress(overallProgress);

      await updateDoc(enrollmentDocRef, {
        [lessonKey]: newProgress,
        Progress: overallProgress,
      });
    }
  };

  const renderContent = () => {
    if (!selectedLesson) {
      return <div>{translatedTextsState.selectLesson}</div>;
    }

    const lessonURL = selectedLesson.URL;

    if (!lessonURL) {
      return <div>{translatedTextsState.noContent}</div>;
    }

    if (lessonURL.includes('zoom.us')) {
      return (
        <a
          className="btn btn-primary"
          href={lessonURL}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => updateLessonProgress(selectedLesson.LessonNumber, 100)}
        >
          {translatedTextsState.joinZoom}
        </a>
      );
    } else if (lessonURL.includes('meet.google.com')) {
      return (
        <a
          className="btn btn-primary"
          href={lessonURL}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => updateLessonProgress(selectedLesson.LessonNumber, 100)}
        >
          {translatedTextsState.joinGoogleMeet}
        </a>
      );
    } else if (lessonURL.includes('.mp4')) {
      return (
        <video
          controls
          style={{ width: '100%', height: '100%' }}
          onTimeUpdate={(e) => handleVideoProgress(e, selectedLesson.LessonNumber)}
        >
          <source src={lessonURL} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    } else if (lessonURL.includes('.pdf')) {
      return (
        <a
          className="btn btn-primary"
          href={lessonURL}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => updateLessonProgress(selectedLesson.LessonNumber, 100)}
        >
          {translatedTextsState.viewPDF}
        </a>
      );
    } else {
      return (
        <a
          className="btn btn-primary"
          href={lessonURL}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => updateLessonProgress(selectedLesson.LessonNumber, 100)}
        >
          {translatedTextsState.openLink}
        </a>
      );
    }
  };

  const handleVideoProgress = async (e, lessonNumber) => {
    const video = e.target;
    const progressRatio = video.currentTime / video.duration;
    const newProgress = Math.min(100, Math.round(progressRatio * 100));

    updateLessonProgress(lessonNumber, newProgress);
  };

  if (loading) {
    return <Spinner animation="border" />;
  }

  if (!course) {
    return <div>Loading...</div>;
  }

  return (
    <Container fluid>
      <Row className="my-3">
        <Col md={8}>
          <h2>{translatedTextsState.courseTitle}: {course.Title}</h2>
        </Col>
        <Col md={4} className="d-flex justify-content-end align-items-center">
          <div className="progress-circle" style={{ '--progress': progress }}>
            <span>{progress}%</span>
          </div>
          <span className="progress-label">{translatedTextsState.myProgress}</span>
        </Col>
      </Row>

      <Row>
        <Col md={8}>
          <div className="video-section mb-3">
            {renderContent()}
          </div>
          <h4 className="lesson-title">{translatedTextsState.lessonTitle} {selectedLesson?.LessonNumber}: {selectedLesson?.Title}</h4>
          <p className="lesson-description">{selectedLesson?.Details}</p>
        </Col>

        <Col md={4}>
          <ListGroup>
            {courseContent.map((lesson, index) => (
              <ListGroup.Item
                key={index}
                active={lesson.Title === selectedLesson?.Title}
                onClick={() => handleLessonClick(lesson)}
                style={{ cursor: 'pointer' }}
              >
                {translatedTextsState.lessonTitle} {lesson.LessonNumber}: {lesson.Title}
                <ProgressBar
                  now={lessonProgress[`Lesson${lesson.LessonNumber}_Progress`] || 0}
                  label={`${lessonProgress[`Lesson${lesson.LessonNumber}_Progress`] || 0}%`}
                  variant="success"
                />
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default MyCourseLearning;
