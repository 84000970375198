import React, { useState, useEffect, useCallback } from 'react';
import './Courses.css';
import { Container, Row, Col, Card, Form, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { collection, getDocs, query, where, addDoc, Timestamp } from 'firebase/firestore';
import { db, auth } from '../firebase';
import translateText from '../utils/translate';

const Courses = ({ language }) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [categoryUid, setCategoryUid] = useState(null);
  const [sessionType, setSessionType] = useState(['Live Sessions', 'Recorded Sessions']);
  const [selectedLevel, setSelectedLevel] = useState('All Levels');
  const [courses, setCourses] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [translatedTexts, setTranslatedTexts] = useState({
    filters: 'Filters',
    categoryLabel: 'Category',
    sessionTypeLabel: 'Session Type',
    levelLabel: 'Level',
    allCategories: 'All Categories',
    liveSessions: 'Live Sessions',
    recordedSessions: 'Recorded Sessions',
    allLevels: 'All Levels',
    beginner: 'Beginner',
    intermediate: 'Intermediate',
    expert: 'Expert',
    addToWishlist: 'Add to Wishlist',
    addedToWishlist: 'Added to Wishlist',
  });

  useEffect(() => {
    const cachedCategories = sessionStorage.getItem('categories');
    if (cachedCategories) {
      setCategories(JSON.parse(cachedCategories));
    } else {
      const fetchCategories = async () => {
        try {
          const querySnapshot = await getDocs(collection(db, 'categories'));
          const categoriesList = querySnapshot.docs.map(doc => ({
            name: doc.data().name,
            uid: doc.id,
          }));
          setCategories(categoriesList);
          sessionStorage.setItem('categories', JSON.stringify(categoriesList));
        } catch (error) {
          console.error('Error fetching categories:', error);
        }
      };
      fetchCategories();
    }
  }, []);

  useEffect(() => {
    const translateCoursesTexts = async () => {
      try {
        const newTranslatedTexts = {
          filters: await translateText('Filters', language),
          categoryLabel: await translateText('Category', language),
          sessionTypeLabel: await translateText('Session Type', language),
          levelLabel: await translateText('Level', language),
          allCategories: await translateText('All Categories', language),
          liveSessions: await translateText('Live Sessions', language),
          recordedSessions: await translateText('Recorded Sessions', language),
          allLevels: await translateText('All Levels', language),
          beginner: await translateText('Beginner', language),
          intermediate: await translateText('Intermediate', language),
          expert: await translateText('Expert', language),
          addToWishlist: await translateText('Add to Wishlist', language),
          addedToWishlist: await translateText('Added to Wishlist', language),
        };
        setTranslatedTexts(newTranslatedTexts);
      } catch (error) {
        console.error('Translation Error:', error);
      }
    };

    translateCoursesTexts();
  }, [language]);

  useEffect(() => {
    const fetchWishlist = async () => {
      const user = auth.currentUser;
      if (user) {
        const wishlistQuery = query(collection(db, 'wishlist'), where('StudentId', '==', user.uid));
        const querySnapshot = await getDocs(wishlistQuery);
        const wishlistItems = querySnapshot.docs.map(doc => doc.data().CourseId);
        setWishlist(wishlistItems);
      }
    };

    fetchWishlist();
  }, []);

  const fetchCourses = useCallback(async (categoryUid = null, sessionType = [], level = 'All Levels') => {
    try {
      let q;

      if (level === 'All Levels') {
        q = query(
          collection(db, 'courses'),
          ...(categoryUid ? [where('categoryUid', '==', categoryUid)] : []),
          where('SessionType', 'in', sessionType)
        );
      } else {
        q = query(
          collection(db, 'courses'),
          ...(categoryUid ? [where('categoryUid', '==', categoryUid)] : []),
          where('SessionType', 'in', sessionType),
          where('Level', '==', level)
        );
      }

      const querySnapshot = await getDocs(q);
      const coursesList = querySnapshot.docs.map(async (doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          title: await translateText(data.Title, language),
          shortDescription: await translateText(data.ShortDescription, language),
          level: await translateText(data.Level, language),
          sessionType: await translateText(data.SessionType, language),
          price: data.Price ? `$${data.Price}` : await translateText('Free', language),
          thumbnail: data.Thumbnail || '/images/about_1.png',
        };
      });

      const resolvedCoursesList = await Promise.all(coursesList); // Wait for all translations to complete
      setCourses(resolvedCoursesList);
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  }, [language]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const categoryUidFromUrl = params.get('category');
    if (categoryUidFromUrl) {
      const selectedCategory = categories.find(category => category.uid === categoryUidFromUrl);
      if (selectedCategory) {
        setSelectedCategory(selectedCategory.name);
        setCategoryUid(categoryUidFromUrl); // Store categoryUid
      }
      fetchCourses(categoryUidFromUrl, sessionType, selectedLevel);
    } else {
      fetchCourses(null, sessionType, selectedLevel); // Fetch all courses if no specific category is selected
    }
  }, [location, categories, sessionType, selectedLevel, fetchCourses]);

  const handleCategoryChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedCategory(selectedValue);

    if (selectedValue === translatedTexts.allCategories) {
      setCategoryUid(null);
      fetchCourses(null, sessionType, selectedLevel);

      const newUrl = `${window.location.pathname}`;
      navigate(newUrl, { replace: true }); // Ensure the URL is reset
    } else {
      const selectedCategory = categories.find(category => category.name === selectedValue);
      if (selectedCategory) {
        const newCategoryUid = selectedCategory.uid;
        setCategoryUid(newCategoryUid);

        const newUrl = `${window.location.pathname}?category=${newCategoryUid}`;
        navigate(newUrl, { replace: true });

        fetchCourses(newCategoryUid, sessionType, selectedLevel);
      }
    }
  };

  const handleSessionTypeChange = (e) => {
    const selectedSessionType = e.target.value;
    const isChecked = e.target.checked;
    const updatedSessionType = isChecked
      ? [...sessionType, selectedSessionType]
      : sessionType.filter(type => type !== selectedSessionType);

    setSessionType(updatedSessionType);

    fetchCourses(categoryUid, updatedSessionType, selectedLevel);
  };

  const handleLevelChange = (e) => {
    const selectedLevel = e.target.value;
    setSelectedLevel(selectedLevel);

    fetchCourses(categoryUid, sessionType, selectedLevel);
  };

  const addToWishlist = async (courseId) => {
    const user = auth.currentUser;
    if (user) {
      if (!wishlist.includes(courseId)) {
        try {
          await addDoc(collection(db, 'wishlist'), {
            CourseId: courseId,
            StudentId: user.uid,
            TimeStamp: Timestamp.now(),
          });
          setWishlist([...wishlist, courseId]); // Add the course to the wishlist state
        } catch (error) {
          console.error('Error adding to wishlist:', error);
        }
      }
    } else {
      navigate('/login'); // Redirect to login if not authenticated
    }
  };

  const isCourseInWishlist = (courseId) => wishlist.includes(courseId);

  return (
    <div className="courses-page">
      <Container>
        <Row>
          <Col md={3} className="filters-section">
            <h4><i className="bi bi-funnel-fill"></i> {translatedTexts.filters}</h4>
            <Form>
              <Form.Group controlId="formCategory" className="mb-3">
                <Form.Label><i className="bi bi-search"></i> {translatedTexts.categoryLabel}</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                >
                  <option>{translatedTexts.allCategories}</option>
                  {categories.map((category, index) => (
                    <option key={index} value={category.name}>
                      {category.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label><i className="bi bi-play-circle"></i> {translatedTexts.sessionTypeLabel}</Form.Label>
                <Form.Check
                  type="checkbox"
                  label={translatedTexts.liveSessions}
                  value="Live Sessions"
                  checked={sessionType.includes('Live Sessions')}
                  onChange={handleSessionTypeChange}
                />
                <Form.Check
                  type="checkbox"
                  label={translatedTexts.recordedSessions}
                  value="Recorded Sessions"
                  checked={sessionType.includes('Recorded Sessions')}
                  onChange={handleSessionTypeChange}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label><i className="bi bi-filter"></i> {translatedTexts.levelLabel}</Form.Label>
                {['All Levels', 'Beginner', 'Intermediate', 'Expert'].map((level, index) => (
                  <Form.Check
                    key={index}
                    type="radio"
                    name="level"
                    label={translatedTexts[level.toLowerCase()] || level}
                    value={level}
                    checked={selectedLevel === level}
                    onChange={handleLevelChange}
                  />
                ))}
              </Form.Group>
            </Form>
          </Col>

          <Col md={9} className="courses-section">
            <div className="course-list">
              {courses.map((course, index) => (
                <Card className="course-card mb-4" key={index}>
                  <Row className="g-0">
                    <Col md={4}>
                      <Card.Img
                        src={course.thumbnail}
                        alt={course.title}
                      />
                    </Col>
                    <Col md={8}>
                      <Card.Body>
                        <div className="d-flex justify-content-between align-items-center">
                          <Card.Title>
                            <Link to={`/course-details/${course.id}`} className="course-title-link">
                              {course.title}
                            </Link>
                          </Card.Title>
                          <span className={`badge bg-primary course-level`}>{course.level}</span>
                        </div>
                        <span className={`badge bg-secondary session-type`}>{course.sessionType}</span>
                        <Card.Text className="mt-2">{course.shortDescription}</Card.Text>
                        <div className="d-flex justify-content-between align-items-center mt-2">
                          <span className="price">{course.price}</span>
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>{isCourseInWishlist(course.id) ? translatedTexts.addedToWishlist : translatedTexts.addToWishlist}</Tooltip>}
                          >
                            <Button
                              variant="link"
                              className="wishlist-btn"
                              onClick={() => addToWishlist(course.id)}
                            >
                              <i className={`bi ${isCourseInWishlist(course.id) ? 'bi-heart-fill' : 'bi-heart'} heart-icon`}></i>
                            </Button>
                          </OverlayTrigger>
                        </div>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Courses;
