import React, { useState, useEffect } from 'react';
import './PopularCoursesSection.css';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, limit, query } from 'firebase/firestore';
import { db } from '../firebase';
import translateText from '../utils/translate';

const PopularCoursesSection = ({ language }) => {
  const [courses, setCourses] = useState([]);
  const [translatedTitle, setTranslatedTitle] = useState('Best Picks for this month');
  const [startingFromText, setStartingFromText] = useState('Starting From');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const coursesQuery = query(collection(db, 'courses'), limit(8));
        const coursesSnapshot = await getDocs(coursesQuery);
        const coursesList = await Promise.all(
          coursesSnapshot.docs.map(async (doc) => ({
            id: doc.id,  // Store the course ID for navigation
            title: await translateText(doc.data().Title, language),
            duration: await translateText(doc.data().Duration, language),
            price: doc.data().Price ? `$${doc.data().Price}` : await translateText('Free', language),
            image: doc.data().Thumbnail || '/images/about_1.png',
          }))
        );
        setCourses(coursesList);
      } catch (error) {
        console.error('Error fetching popular courses:', error);
      }
    };

    fetchCourses();
  }, [language]);

  useEffect(() => {
    const translateSectionText = async () => {
      const translatedTitle = await translateText('Best Picks for this month', language);
      const translatedStartingFrom = await translateText('Starting From', language);

      setTranslatedTitle(translatedTitle);
      setStartingFromText(translatedStartingFrom);
    };

    translateSectionText();
  }, [language]);

  const handleCourseClick = (courseId) => {
    navigate(`/course-details/${courseId}`);
  };

  return (
    <div className="popular-courses-section">
      <Container>
        <h2 className="section-title">{translatedTitle}</h2>
        <Row className="courses-row">
          {courses.map((course, index) => (
            <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
              <Card className="course-card" onClick={() => handleCourseClick(course.id)}>
                <Card.Img variant="top" src={course.image} />
                <Card.Body>
                  <Card.Title>{course.title}</Card.Title>
                  <Card.Text>
                    <span>Course Duration</span><br />
                    <span>{course.duration}</span><br />
                    <span className="course-price">{startingFromText} {course.price}</span>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default PopularCoursesSection;
