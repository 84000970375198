import React, { useState, useEffect } from 'react';
import { Table, Container, Modal, Image } from 'react-bootstrap';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import './Users.css';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const q = query(collection(db, 'students'), where('Role', '==', 'Student'));
        const querySnapshot = await getDocs(q);
        const usersList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(usersList);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedImage(null);
  };

  return (
    <Container className="mt-4">
      <h2>Student Users</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Avatar</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>City</th>
            <th>Country</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id}>
              <td>
                <img
                  src={user.ProfilePicture || '/images/default_avatar.png'}
                  alt="no-image"
                  className="avatar-img"
                  onClick={() => handleImageClick(user.ProfilePicture || '/images/default_avatar.png')}
                  style={{ cursor: 'pointer' }}
                />
              </td>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{`${user.PhoneCountryCode} ${user.PhoneNumber}`}</td>
              <td>{user.City || 'N/A'}</td>
              <td>{user.Country || 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for full view of the image */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Body className="text-center">
          <Image src={selectedImage} alt="Profile" fluid />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Users;
