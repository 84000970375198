import React, { useEffect, useState } from 'react';
import './Footer.css';
import { Container, Row, Col } from 'react-bootstrap';
import { doc, getDoc } from "firebase/firestore"; // Import Firestore methods
import { db } from '../firebase'; // Adjust the path as needed

const Footer = ({ language }) => {
  const [translatedText, setTranslatedText] = useState(null); // Initialize to null

  // Fetch translations from Firestore based on the selected language
  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        const docRef = doc(db, 'translations', 'Footer');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const translationData = JSON.parse(data[language]); // Parse the string to JSON
          setTranslatedText(translationData); // Update state with the parsed data
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      }
    };

    fetchTranslations();
  }, [language]);

  return (
    <footer className="footer-section">
      <Container fluid className="footer-container">
        <Col className="footer-column">
          <div className="footer-logo">
            <img src="/images/logo_white.png" alt="Oasee" />
          </div>
        </Col>
        {translatedText && (
          <>
            <Col className="footer-column">
              <ul className="footer-links">
                <li><a href="/home">{translatedText.home}</a></li>
                <li><a href="/about">{translatedText.about}</a></li>
                <li><a href="/booking">{translatedText.bookYourHour}</a></li>
              </ul>
            </Col>
            <Col className="footer-column">
              <ul className="footer-links">
                <li><a href="/terms">{translatedText.termsAndConditions}</a></li>
                <li><a href="/privacy">{translatedText.privacyPolicy}</a></li>
                <li><a href="/career">{translatedText.career}</a></li>
              </ul>
            </Col>
            <Col className="footer-column footer-contact">
              <h5>{translatedText.contactUs}</h5>
              <p>info@oasee.ch</p>
              <p>+41 79 660 51 51</p>
            </Col>
            <Col className="footer-column">
              <h5>{translatedText.socialMedia}</h5>
              <ul className="footer-social">
                <li><a href="https://www.linkedin.com/company/oasee-ch" target="_blank" rel="noopener noreferrer"><i className="bi bi-linkedin"></i></a></li>
                <li><a href="https://www.facebook.com/profile.php?id=61558257116233" target="_blank" rel="noopener noreferrer"><i className="bi bi-facebook"></i></a></li>
                <li><a href="https://www.instagram.com/oasee-ch" target="_blank" rel="noopener noreferrer"><i className="bi bi-instagram"></i></a></li>
              </ul>
              <h5>{translatedText.paymentMethods}</h5>
              <div className="payment-methods">
                <img className='visa-method' src="/payments/visa.png" alt="Visa" />
                <img src="/payments/mastercard.png" alt="MasterCard" />
                <img src="/images/paypal.png" alt="PayPal" />
                <img src="/payments/applepay.png" alt="Apple Pay" />
                <img src="/payments/gpay.png" alt="Google Pay" />
              </div>
            </Col>
          </>
        )}
      </Container>
      <Container fluid>
        <Row>
          <Col xs={12} className="text-center">
            {translatedText && (
              <p>{translatedText.footerText} 
                <img src="/images/swiss_flag.jpg" alt="Swiss Flag" className="swiss-flag" />
                CHE-320.351.496
              </p>
            )}
          </Col>
          <hr/>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
