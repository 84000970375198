import React, { useState, useEffect } from 'react';
import { Table, Container, Button, Modal, Form } from 'react-bootstrap';
import { collection, getDocs, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { format } from 'date-fns';

const Consultations = () => {
  const [consultations, setConsultations] = useState([]);
  const [selectedConsultation, setSelectedConsultation] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [updatedTimeslot, setUpdatedTimeslot] = useState('');
  const [price, setPrice] = useState('');
  const [coachNotes, setCoachNotes] = useState('');
  const [meetingLink, setMeetingLink] = useState('');
  const [paymentLink, setPaymentLink] = useState('');
  const [isApproved, setIsApproved] = useState(false);
  const [isPaid, setIsPaid] = useState(false);

  useEffect(() => {
    const fetchConsultations = async () => {
      try {
        const bookingsSnapshot = await getDocs(collection(db, 'bookings'));
        const consultationsData = [];

        for (const bookingDoc of bookingsSnapshot.docs) {
          const bookingData = bookingDoc.data();
          const userId = bookingData.UserId;

          const studentDoc = await getDoc(doc(db, 'students', userId));
          const studentData = studentDoc.data();

          const timeslot = bookingData.Timeslot.toDate();

          consultationsData.push({
            id: bookingDoc.id,
            FullName: studentData.name,
            Notes: bookingData.Notes,
            CoachNotes: bookingData.CoachNotes || '',
            Timeslot: timeslot,
            Status: bookingData.Status,
            PaymentStatus: bookingData.PaymentStatus || 'Pending',
            Price: bookingData.Price || 'N/A',
            Link: bookingData.Link || '',
            PaymentLink: bookingData.PaymentLink || '',
          });
        }

        setConsultations(consultationsData);
      } catch (error) {
        console.error('Error fetching consultations:', error);
      }
    };

    fetchConsultations();
  }, []);

  const handleViewRequest = (consultation) => {
    setSelectedConsultation(consultation);
    setUpdatedTimeslot(format(consultation.Timeslot, "yyyy-MM-dd'T'HH:mm"));
    setPrice(consultation.Price !== 'N/A' ? consultation.Price : '');
    setCoachNotes(consultation.CoachNotes || '');
    setMeetingLink(consultation.Link || '');
    setPaymentLink(consultation.PaymentLink || '');
    setIsApproved(consultation.Status === 'Approved');
    setIsPaid(consultation.PaymentStatus === 'Paid');
    setShowModal(true);
  };

  const handleSaveChanges = async () => {
    if (!selectedConsultation) return;

    try {
      const bookingRef = doc(db, 'bookings', selectedConsultation.id);

      await updateDoc(bookingRef, {
        Timeslot: new Date(updatedTimeslot),
        Price: price,
        CoachNotes: coachNotes,
        Link: meetingLink,
        PaymentLink: paymentLink,
        Status: isApproved ? 'Approved' : 'Pending',
        PaymentStatus: isPaid ? 'Paid' : 'Not Paid',
      });

      setConsultations((prevConsultations) =>
        prevConsultations.map((consultation) =>
          consultation.id === selectedConsultation.id
            ? {
                ...consultation,
                Timeslot: new Date(updatedTimeslot),
                Price: price !== '' ? price : 'N/A',
                CoachNotes: coachNotes,
                Link: meetingLink,
                PaymentLink: paymentLink,
                Status: isApproved ? 'Approved' : 'Pending',
                PaymentStatus: isPaid ? 'Paid' : 'Not Paid',
              }
            : consultation
        )
      );

      setShowModal(false);
    } catch (error) {
      console.error('Error updating consultation:', error);
    }
  };

  return (
    <Container>
      <h1>Consultations</h1>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Status</th>
            <th>Full Name</th>
            <th>Notes</th>
            <th>Timeslot</th>
            <th>Price</th>
            <th>Payment Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {consultations.map((consultation, index) => (
            <tr key={index}>
              <td>{consultation.Status}</td>
              <td>{consultation.FullName}</td>
              <td>
                {consultation.Notes}
                {consultation.CoachNotes && (
                  <>
                    <br />
                    <strong>Coach Notes:</strong> {consultation.CoachNotes}
                  </>
                )}
              </td>
              <td>{format(consultation.Timeslot, 'MMMM dd, yyyy')} at {format(consultation.Timeslot, 'hh:mm a')} {Intl.DateTimeFormat().resolvedOptions().timeZone}</td>
              <td>{consultation.Price}</td>
              <td>{consultation.PaymentStatus}</td>
              <td>
                <Button onClick={() => handleViewRequest(consultation)}>View Request</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {selectedConsultation && (
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Consultation Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Button
                variant="success"
                className="mb-3"
                onClick={() => setIsPaid(true)}
                disabled={isPaid}
              >
                {isPaid ? 'Payment Verified' : 'Mark as Paid'}
              </Button>

              <Form.Group controlId="formTimeslot">
                <Form.Label>Timeslot</Form.Label>
                <Form.Control
                  type="datetime-local"
                  value={updatedTimeslot}
                  onChange={(e) => setUpdatedTimeslot(e.target.value)}
                />
                <Form.Text className="text-muted">
                  This timeslot is booked by the user. You can change it by simply modifying this textbox.
                </Form.Text>
              </Form.Group>

              <Form.Group controlId="formPrice" className="mt-3">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formPaymentLink" className="mt-3">
                <Form.Label>Payment Link</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter payment link"
                  value={paymentLink}
                  onChange={(e) => setPaymentLink(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formCoachNotes" className="mt-3">
                <Form.Label>Coach Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter notes for the student"
                  value={coachNotes}
                  onChange={(e) => setCoachNotes(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formMeetingLink" className="mt-3">
                <Form.Label>Meeting Link</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter meeting link (Zoom, Google Meet, etc.)"
                  value={meetingLink}
                  onChange={(e) => setMeetingLink(e.target.value)}
                />
              </Form.Group>

              <Form.Check
                className="mt-3"
                type="checkbox"
                label="Approve Consultation"
                checked={isApproved}
                onChange={(e) => setIsApproved(e.target.checked)}
                disabled={selectedConsultation.Status === 'Approved'}
              />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSaveChanges}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

export default Consultations;
