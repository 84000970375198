import React from 'react';
import { Navigate } from 'react-router-dom';
import { auth, db } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';

const AdminRoute = ({ children }) => {
  const [isAdmin, setIsAdmin] = React.useState(null);

  React.useEffect(() => {
    const checkAdminRole = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'students', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists() && userDoc.data().Role === 'Admin') {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      } else {
        setIsAdmin(false);
      }
    };

    checkAdminRole();
  }, []);

  if (isAdmin === null) {
    return <div>Loading...</div>; // Show loading state while checking role
  }

  return isAdmin ? children : <Navigate to="/" />;
};

export default AdminRoute;
