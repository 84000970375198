import React, { useState, useEffect } from 'react';
import './Wishlist.css';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db, auth } from '../firebase'; // Ensure the correct path to firebase.js
import translateText from '../utils/translate';

const Wishlist = ({ language }) => {
  const [wishlistCourses, setWishlistCourses] = useState([]);
  const [translatedTexts, setTranslatedTexts] = useState({
    wishlistTitle: 'Your Wishlist',
    noCourses: 'You have no courses in your wishlist.',
  });

  useEffect(() => {
    const fetchWishlistCourses = async () => {
      const user = auth.currentUser;
      if (user) {
        const wishlistQuery = query(collection(db, 'wishlist'), where('StudentId', '==', user.uid));
        const wishlistSnapshot = await getDocs(wishlistQuery);
        const courseIds = wishlistSnapshot.docs.map(doc => doc.data().CourseId);

        if (courseIds.length > 0) {
          const coursesQuery = query(collection(db, 'courses'), where('__name__', 'in', courseIds));
          const coursesSnapshot = await getDocs(coursesQuery);
          const coursesList = coursesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

          // Translate course titles and descriptions
          const translatedCourses = await Promise.all(
            coursesList.map(async (course) => {
              const translatedTitle = await translateText(course.Title, language);
              const translatedShortDescription = await translateText(course.ShortDescription, language);
              return {
                ...course,
                Title: translatedTitle,
                ShortDescription: translatedShortDescription,
              };
            })
          );

          setWishlistCourses(translatedCourses);
        }
      }
    };

    fetchWishlistCourses();
  }, [language]);

  useEffect(() => {
    const translateStaticTexts = async () => {
      const translatedWishlistTitle = await translateText('Your Wishlist', language);
      const translatedNoCourses = await translateText('You have no courses in your wishlist.', language);
      setTranslatedTexts({
        wishlistTitle: translatedWishlistTitle,
        noCourses: translatedNoCourses,
      });
    };

    translateStaticTexts();
  }, [language]);

  return (
    <div className="wishlist-page">
      <Container>
        <Row>
          <Col>
            <h2>{translatedTexts.wishlistTitle}</h2>
            <div className="wishlist-course-list">
              {wishlistCourses.length > 0 ? (
                wishlistCourses.map((course, index) => (
                  <Card className="course-card mb-4" key={index}>
                    <Row className="g-0">
                      <Col md={4}>
                        <Card.Img
                          src={course.Thumbnail ? course.Thumbnail : '/images/about_1.png'}
                          alt={course.Title}
                        />
                      </Col>
                      <Col md={8}>
                        <Card.Body>
                          <div className="d-flex justify-content-between align-items-center">
                            <Card.Title>
                              <Link to={`/course-details/${course.id}`} className="course-title-link">
                                {course.Title}
                              </Link>
                            </Card.Title>
                            <span className={`badge bg-primary course-level`}>{course.Level}</span>
                          </div>
                          <span className={`badge bg-secondary session-type`}>{course.SessionType}</span>
                          <Card.Text className="mt-2">{course.ShortDescription}</Card.Text>
                          <div className="d-flex justify-content-between align-items-center mt-2">
                            <span className="price">{course.Price}</span>
                          </div>
                        </Card.Body>
                      </Col>
                    </Row>
                  </Card>
                ))
              ) : (
                <p>{translatedTexts.noCourses}</p>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Wishlist;
