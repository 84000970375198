import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate} from 'react-router-dom';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { auth, db } from '../firebase';

const SuccessPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const courseId = searchParams.get('courseId');
  const amount = searchParams.get('amount');
  const [isProcessing, setIsProcessing] = useState(true);

  useEffect(() => {
    const saveEnrollment = async () => {
      const user = auth.currentUser;

      if (!user) {
        console.log("User not authenticated yet.");
        return;
      }

      if (user && courseId) {
        try {
          const enrollmentRef = doc(db, 'enrolledcourses', `${user.uid}_${courseId}`);
          await setDoc(enrollmentRef, {
            StudentId: user.uid,
            CourseId: courseId,
            Amount: amount,
            TimeStamp: serverTimestamp(),
          });
        } catch (error) {
          console.error('Error adding enrollment:', error);
        } finally {
          setIsProcessing(false);
          navigate('/my-courses');
        }
      }
    };

    const checkUserAndSaveEnrollment = () => {
      if (!auth.currentUser) {
        const unsubscribe = auth.onAuthStateChanged((user) => {
          if (user) {
            saveEnrollment();
            unsubscribe();
          }
        });
      } else {
        saveEnrollment();
      }
    };

    checkUserAndSaveEnrollment();
  }, [courseId, amount, navigate]);

  return (
    <div className="success-page">
      <h1>Payment Successful!</h1>
      <p>Thank you for your purchase. You will be redirected to your courses shortly.</p>
      {isProcessing && <p>Processing your enrollment...</p>}
    </div>
  );
};

export default SuccessPage;
