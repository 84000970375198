import React, { useState, useEffect } from 'react';
import './Booking.css';
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import { auth, db } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { useNavigate, Link } from 'react-router-dom';

const Booking = ({ language }) => {
  const [validated, setValidated] = useState(false);
  const [user, setUser] = useState(null);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [timeslot, setTimeslot] = useState('');
  const [notes, setNotes] = useState('');
  const [loading, setLoading] = useState(false);
  const [translatedTexts, setTranslatedTexts] = useState(null); // Initialize to null

  const navigate = useNavigate();

  // Fetch translations from Firestore based on the selected language
  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        const docRef = doc(db, 'translations', 'Booking');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const translationData = JSON.parse(data[language]); // Parse the string to JSON
          setTranslatedTexts(translationData); // Update state with the parsed data
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      }
    };

    fetchTranslations();
  }, [language]);

  // Fetch user data when authenticated
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        const userDoc = await getDoc(doc(db, 'students', currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setFullName(userData.name || '');
          setEmail(currentUser.email || '');
        }
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoading(true); // Start loading spinner

      try {
        const bookingsCollectionRef = collection(db, 'bookings');
        const userBookingQuery = query(bookingsCollectionRef, where('UserId', '==', auth.currentUser.uid));
        const userBookingSnapshot = await getDocs(userBookingQuery);

        const bookingCount = userBookingSnapshot.docs.length;
        const bookingDocId = `${auth.currentUser.uid}_${bookingCount + 1}`;

        const bookingData = {
          UserId: auth.currentUser.uid,
          Timeslot: new Date(timeslot),
          Notes: notes,
          Status: 'Waiting For Approval',
        };

        await setDoc(doc(db, 'bookings', bookingDocId), bookingData);
        navigate('/my-sessions'); // Redirect to my-sessions page after successful booking
      } catch (error) {
        console.error('Error booking session:', error);
      } finally {
        setLoading(false); // Stop loading spinner
      }
    }

    setValidated(true);
  };

  return (
    <div className="booking-page">
      <div className="booking-cover">
        <Container>
          {translatedTexts && <h1 className="booking-heading">{translatedTexts.bookingHeading}</h1>}
        </Container>
      </div>

      <Container>
        <div className="content-spacing"></div>
        <Row>
          <Col md={6}>
            {!user ? (
              <div className="auth-prompt">
                {translatedTexts && (
                  <p>
                    {translatedTexts.authPrompt}{' '}
                    <Link to="/register">{translatedTexts.registerLink}</Link> or{' '}
                    <Link to="/login">{translatedTexts.signInLink}</Link>.
                  </p>
                )}
              </div>
            ) : (
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group controlId="formFullName" className="mb-3">
                  <Form.Label>{translatedTexts && translatedTexts.fullNameLabel}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={translatedTexts ? translatedTexts.fullNameLabel : ''}
                    value={fullName}
                    disabled
                  />
                </Form.Group>

                <Form.Group controlId="formEmail" className="mb-3">
                  <Form.Label>{translatedTexts && translatedTexts.emailLabel}</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder={translatedTexts ? translatedTexts.emailLabel : ''}
                    value={email}
                    disabled
                  />
                </Form.Group>

                <Form.Group controlId="formTimeslot" className="mb-3">
                  <Form.Label>{translatedTexts && translatedTexts.timeslotLabel}</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    required
                    value={timeslot}
                    onChange={(e) => setTimeslot(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please select a timeslot.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formNotes" className="mb-3">
                  <Form.Label>{translatedTexts && translatedTexts.notesLabel}</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    placeholder={translatedTexts ? translatedTexts.notesLabel : ''}
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </Form.Group>

                <Button variant="primary" type="submit" disabled={loading}>
                  {loading ? <Spinner as="span" animation="border" size="sm" /> : translatedTexts && translatedTexts.submitButton}
                </Button>
              </Form>
            )}
          </Col>

          <Col md={6}>
            {translatedTexts && (
              <>
                <h3>{translatedTexts.bookConsultationTitle}</h3>
                <p>{translatedTexts.bookConsultationText1}</p>
                <p>{translatedTexts.bookConsultationText2}</p>
                <p>{translatedTexts.bookConsultationText3}</p>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Booking;
