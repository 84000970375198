import React, { useState, useEffect, useCallback } from 'react';
import './MyCourses.css';
import { Container, Row, Col, Card, ProgressBar } from 'react-bootstrap';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import translateText from '../utils/translate';

const MyCourses = ({ language }) => {
  const [courses, setCourses] = useState([]);
  const [translatedTexts, setTranslatedTexts] = useState({
    myCourses: 'My Enrolled Courses',
    noCourses: "You haven't enrolled in any courses yet.",
    purchasedOn: 'You purchased this course on',
    at: 'at',
    progress: 'Progress:',
  });

  const user = auth.currentUser;

  const translateStaticTexts = useCallback(async () => {
    const newTranslatedTexts = {};
    for (const [key, value] of Object.entries(translatedTexts)) {
      newTranslatedTexts[key] = await translateText(value, language);
    }
    setTranslatedTexts(newTranslatedTexts);
  }, [language, translatedTexts]);

  const translateCourseData = useCallback(async (coursesList) => {
    const translatedCourses = await Promise.all(
      coursesList.map(async (course) => {
        const translatedTitle = await translateText(course.Title, language);
        const translatedShortDescription = await translateText(course.ShortDescription, language);
        return {
          ...course,
          Title: translatedTitle,
          ShortDescription: translatedShortDescription,
        };
      })
    );
    setCourses(translatedCourses);
  }, [language]);

  useEffect(() => {
    const fetchEnrolledCourses = async () => {
      if (user) {
        const q = query(
          collection(db, 'enrolledcourses'),
          where('StudentId', '==', user.uid)
        );

        const querySnapshot = await getDocs(q);
        const coursesList = [];

        for (const docSnapshot of querySnapshot.docs) {
          const courseData = docSnapshot.data();
          const courseRef = doc(db, 'courses', courseData.CourseId);
          const courseDoc = await getDoc(courseRef);

          if (courseDoc.exists()) {
            coursesList.push({
              ...courseDoc.data(),
              CourseId: courseData.CourseId,
              TimeStamp: courseData.TimeStamp,
              Progress: courseData.Progress || 0, // Fetch progress, default to 0 if not found
            });
          }
        }

        translateCourseData(coursesList);
      }
    };

    fetchEnrolledCourses();
  }, [user, language, translateCourseData]);

  useEffect(() => {
    translateStaticTexts();
  }, [translateStaticTexts]);

  return (
    <div className="my-courses-page">
      <Container>
        <Row>
          <Col>
            <h2>{translatedTexts.myCourses}</h2>
            {courses.length === 0 ? (
              <p>{translatedTexts.noCourses}</p>
            ) : (
              <div className="course-list">
                {courses.map((course, index) => (
                  <Card className="course-card mb-4" key={index}>
                    <Row className="g-0">
                      <Col md={4}>
                        <Card.Img
                          src={course.Thumbnail ? course.Thumbnail : '/images/about_1.png'}
                          alt={course.Title}
                        />
                      </Col>
                      <Col md={8}>
                        <Card.Body>
                          <Card.Title>
                            <Link to={`/mylearning/${course.CourseId}`} className="course-title-link">
                              {course.Title}
                            </Link>
                          </Card.Title>
                          <Card.Text className="mt-2">{course.ShortDescription}</Card.Text>
                          <Card.Text className="purchase-info">
                            {translatedTexts.purchasedOn}{' '}
                            {course.TimeStamp instanceof Date
                              ? format(course.TimeStamp, 'MMMM dd, yyyy')
                              : format(course.TimeStamp.toDate(), 'MMMM dd, yyyy')} {translatedTexts.at}{' '}
                            {course.TimeStamp instanceof Date
                              ? format(course.TimeStamp, 'HH:mm')
                              : format(course.TimeStamp.toDate(), 'HH:mm')}.
                          </Card.Text>
                          <div className="course-progress">
                            <span>{translatedTexts.progress}</span>
                            <ProgressBar
                              now={course.Progress}
                              label={`${course.Progress}%`}
                              variant="success"
                            />
                          </div>
                        </Card.Body>
                      </Col>
                    </Row>
                  </Card>
                ))}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MyCourses;
