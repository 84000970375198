// src/superadmin/pages/DashboardMain.js

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import './DashboardMain.css';

const DashboardMain = () => {
  const [categoriesCount, setCategoriesCount] = useState(0);
  const [coursesCount, setCoursesCount] = useState(0);
  const [bookingRequestsCount, setBookingRequestsCount] = useState(0);
  const [studentsCount, setStudentsCount] = useState(0); // Renamed state to studentsCount

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const categoriesSnapshot = await getDocs(collection(db, 'categories'));
        setCategoriesCount(categoriesSnapshot.size);

        const coursesSnapshot = await getDocs(collection(db, 'courses'));
        setCoursesCount(coursesSnapshot.size);

        const bookingRequestsSnapshot = await getDocs(collection(db, 'bookings'));
        setBookingRequestsCount(bookingRequestsSnapshot.size);

        // Query to count students with the "Student" role
        const studentsQuery = query(collection(db, 'students'), where('Role', '==', 'Student'));
        const studentsSnapshot = await getDocs(studentsQuery);
        setStudentsCount(studentsSnapshot.size);
      } catch (error) {
        console.error('Error fetching counts:', error);
      }
    };

    fetchCounts();
  }, []);

  return (
    <Container className="dashboard-main">
      <Row className="mb-4">
        <Col md={3}>
          <Card className="dashboard-card">
            <Card.Body>
              <Card.Title>Categories</Card.Title>
              <Card.Text>{categoriesCount} Total</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="dashboard-card">
            <Card.Body>
              <Card.Title>Courses</Card.Title>
              <Card.Text>{coursesCount} Total</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="dashboard-card">
            <Card.Body>
              <Card.Title>Booking Requests</Card.Title>
              <Card.Text>{bookingRequestsCount} Total</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="dashboard-card">
            <Card.Body>
              <Card.Title>Students</Card.Title> {/* Changed title to Students */}
              <Card.Text>{studentsCount} Total</Card.Text> {/* Displaying studentsCount */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardMain;
