// src/utils/translate.js

import axios from 'axios';

const translateText = async (text, targetLanguage) => {
  try {
    const response = await axios.post('https://us-central1-oasee-study-smart.cloudfunctions.net/translateText', {
      text,
      targetLanguage,
    });
    return response.data.translation;
  } catch (error) {
    console.error('Translation Error:', error);
    return text; // Fallback to the original text in case of an error
  }
};

export default translateText;
