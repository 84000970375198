// src/superadmin/pages/AddCourse.js

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { db, storage } from '../../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const initialCourseState = {
  Title: '',
  ShortDescription: '',
  LongDescription: '',
  SessionType: 'Live Sessions',
  StartingDate: '',
  Level: 'Beginner',
  CompletionDate: '',
  Thumbnail: '',
  Price: '',
  Schedule: '',
  Duration: '',
  DownloadableResources: 0,
  IsQuiz: false,
  IsTest: false,
  IsTheory: false,
  categoryUid: '', // New category ID field
};

const initialLessonState = {
  LessonNumber: '',
  Title: '',
  Details: '',
  URL: '',
};

const AddCourse = () => {
  const [courseData, setCourseData] = useState(initialCourseState);
  const [lessons, setLessons] = useState([initialLessonState]);
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      const categorySnapshot = await getDocs(collection(db, 'categories'));
      const categoryList = categorySnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name,
      }));
      setCategories(categoryList);
    };
    fetchCategories();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCourseData({
      ...courseData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleLessonChange = (index, e) => {
    const { name, value } = e.target;
    setLessons(prevLessons => {
      const newLessons = [...prevLessons];
      newLessons[index] = {
        ...newLessons[index],
        [name]: value
      };
      return newLessons;
    });
  };

  const handleFileUpload = async (index, file) => {
    const storageRef = ref(storage, `courseContent/${file.name}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
  
    setLessons(prevLessons => {
      const newLessons = [...prevLessons];
      newLessons[index] = {
        ...newLessons[index],
        URL: downloadURL
      };
      return newLessons;
    });
  };


  const handleAddLesson = () => {
    setLessons(prevLessons => [...prevLessons, initialLessonState]);
  };


  const handleSaveCourse = async (e) => {
    e.preventDefault();
    try {
      // Upload the thumbnail and get the URL
      let thumbnailURL = '';
      if (thumbnailFile) {
        const storageRef = ref(storage, `courseThumbnails/${thumbnailFile.name}`);
        await uploadBytes(storageRef, thumbnailFile);
        thumbnailURL = await getDownloadURL(storageRef);
      }
  
      // Add the thumbnail URL to the course data
      const updatedCourseData = {
        ...courseData,
        Thumbnail: thumbnailURL, // Save the URL in the Thumbnail attribute
      };
  
      // Save the course data to Firestore
      const courseDocRef = await addDoc(collection(db, 'courses'), updatedCourseData);
      const courseId = courseDocRef.id;
  
      // Save each lesson to the courseContent collection
      for (let i = 0; i < lessons.length; i++) {
        await addDoc(collection(db, 'courseContent'), {
          ...lessons[i],
          CourseId: courseId,
        });
      }
  
      navigate('/superadmin/course-management'); // Redirect to the courses page after saving
    } catch (error) {
      console.error('Error adding course: ', error);
    }
  };
  

  return (
    <Container>
      <Row className="my-4">
        <Col>
          <h2>Add New Course</h2>
        </Col>
      </Row>
      <Form onSubmit={handleSaveCourse}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formCategory" className="mb-3">
              <Form.Label>Category</Form.Label>
              <Form.Control
                as="select"
                name="categoryUid"
                value={courseData.categoryUid}
                onChange={handleInputChange}
              >
                <option value="">Select a category</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formTitle" className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter course title"
                name="Title"
                value={courseData.Title}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="formDescription" className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter course description"
                name="ShortDescription"
                value={courseData.ShortDescription}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="formDetails" className="mb-3">
              <Form.Label>Details</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter detailed information"
                name="LongDescription"
                value={courseData.LongDescription}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="formSessionType" className="mb-3">
              <Form.Label>Session Type</Form.Label>
              <Form.Control
                as="select"
                name="SessionType"
                value={courseData.SessionType}
                onChange={handleInputChange}
              >
                <option>Live Sessions</option>
                <option>Recorded Sessions</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formStartingDate" className="mb-3">
              <Form.Label>Starting Date</Form.Label>
              <Form.Control
                type="date"
                name="StartingDate"
                value={courseData.StartingDate}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="formLevel" className="mb-3">
              <Form.Label>Level</Form.Label>
              <Form.Control
                as="select"
                name="Level"
                value={courseData.Level}
                onChange={handleInputChange}
              >
                <option>Beginner</option>
                <option>Intermediate</option>
                <option>Expert</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formCompletionDate" className="mb-3">
              <Form.Label>Completion Date</Form.Label>
              <Form.Control
                type="date"
                name="CompletionDate"
                value={courseData.CompletionDate}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formThumbnail" className="mb-3">
              <Form.Label>Thumbnail</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => setThumbnailFile(e.target.files[0])}
              />
            </Form.Group>

            <Form.Group controlId="formPrice" className="mb-3">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter course price"
                name="Price"
                value={courseData.Price}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="formSchedule" className="mb-3">
              <Form.Label>Schedule</Form.Label>
              <Form.Control
                type="text"
                placeholder="Every Friday at 02:00 PM"
                name="Schedule"
                value={courseData.Schedule}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="formDuration" className="mb-3">
              <Form.Label>Duration</Form.Label>
              <Form.Control
                type="text"
                placeholder="60 Minutes or 4 Weeks etc"
                name="Duration"
                value={courseData.Duration}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="formDownloadableResources" className="mb-3">
              <Form.Label>Downloadable Resources</Form.Label>
              <Form.Control
                type="number"
                placeholder="2 or 5 or 10"
                name="DownloadableResources"
                value={courseData.DownloadableResources}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Check
              type="checkbox"
              label="Quiz Included"
              name="IsQuiz"
              checked={courseData.IsQuiz}
              onChange={handleInputChange}
            />

            <Form.Check
              type="checkbox"
              label="Test Included"
              name="IsTest"
              checked={courseData.IsTest}
              onChange={handleInputChange}
            />

            <Form.Check
              type="checkbox"
              label="Theory Included"
              name="IsTheory"
              checked={courseData.IsTheory}
              onChange={handleInputChange}
            />
          </Col>
        </Row>

        {/* Lessons Section */}
        <Row>
          <Col>
            <h3>Course Lessons</h3>
            {lessons.map((lesson, index) => (
              <div key={index} className="lesson-section">
                <h5>Lesson {index + 1}</h5>
                <Form.Group controlId={`formLessonNumber${index}`} className="mb-3">
                  <Form.Label>Lesson Number</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter lesson number"
                    name="LessonNumber"
                    value={lesson.LessonNumber}
                    onChange={(e) => handleLessonChange(index, e)}
                  />
                </Form.Group>

                <Form.Group controlId={`formLessonTitle${index}`} className="mb-3">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter lesson title"
                    name="Title"
                    value={lesson.Title}
                    onChange={(e) => handleLessonChange(index, e)}
                  />
                </Form.Group>

                <Form.Group controlId={`formLessonDetails${index}`} className="mb-3">
                  <Form.Label>Details</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter lesson details"
                    name="Details"
                    value={lesson.Details}
                    onChange={(e) => handleLessonChange(index, e)}
                  />
                </Form.Group>

                <Form.Group controlId={`formLessonContent${index}`} className="mb-3">
                  <Form.Label>Content URL or Upload</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter URL or upload a file"
                    name="URL"
                    value={lesson.URL}
                    onChange={(e) => handleLessonChange(index, e)}
                  />
                  <Form.Control
                    type="file"
                    className="mt-2"
                    onChange={(e) => handleFileUpload(index, e.target.files[0])}
                  />
                </Form.Group>
              </div>
            ))}
            <Button variant="secondary" onClick={handleAddLesson}>
              Add Another Lesson
            </Button>
          </Col>
        </Row>

        <Button variant="primary" type="submit" className="mt-3">
          Save Course
        </Button>
      </Form>
    </Container>
  );
};

export default AddCourse;
