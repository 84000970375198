import React, { useState, useEffect } from 'react';
import './About.css';
import { Container, Row, Col } from 'react-bootstrap';
import { doc, getDoc } from "firebase/firestore"; // Import Firestore methods
import { db } from '../firebase'; // Adjust the path as needed

const About = ({ language }) => {
  const [translatedTexts, setTranslatedTexts] = useState(null); // Start with null

  // Fetch translations from Firestore based on the selected language
  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        const docRef = doc(db, 'translations', 'About');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const translationData = JSON.parse(data[language]); // Parse the string to JSON
          setTranslatedTexts(translationData); // Update state with the parsed data
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      }
    };

    fetchTranslations();
  }, [language]);

  return (
    <div className="about-page">
      {/* Use Container fluid to reduce left and right space */}
      <Container fluid>
        {translatedTexts && (
          <>
            <Row className="align-items-center mb-5">
              <Col md={1}></Col>
              {/* Reduce the column width to span more space */}
              <Col md={5} className="about-text">
                <h2>{translatedTexts.heading1}</h2>
                <p>{translatedTexts.paragraph1}</p>
              </Col>
              <Col md={4}>
                <img src="/images/about_1.png" alt="Team" className="img-fluid" />
              </Col>
            </Row>

            <div className="mission-section py-5">
              <Container fluid>
                <Row className="align-items-center">
                  <Col md={6}>
                    <img src="/images/about_2.png" alt="Mission" className="img-fluid" />
                  </Col>
                  <Col md={6} className="mission-text">
                    <h2>{translatedTexts.heading2}</h2>
                    <p>{translatedTexts.paragraph2}</p>
                  </Col>
                </Row>
              </Container>
            </div>

            <div className="testimonials-section py-5">
              <Container fluid>
                <Row>
                  <Col md={4}>
                    <div className="testimonial">
                      <p>{translatedTexts.testimonial1}</p>
                      <small>{translatedTexts.testimonialAuthor1}</small>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="testimonial">
                      <p>{translatedTexts.testimonial2}</p>
                      <small>{translatedTexts.testimonialAuthor2}</small>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="testimonial">
                      <p>{translatedTexts.testimonial3}</p>
                      <small>{translatedTexts.testimonialAuthor3}</small>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </>
        )}
      </Container>
    </div>
  );
};

export default About;
