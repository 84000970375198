import React, { useState, useEffect } from 'react';
import './HeroSection.css';
import { Container, Row, Col } from 'react-bootstrap';
import { doc, getDoc, collection, getDocs } from "firebase/firestore"; // Add missing imports
import { db } from '../firebase'; // Ensure this path is correct
import { Link } from 'react-router-dom';

const HeroSection = ({ language }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [placeholder, setPlaceholder] = useState(''); // Start with an empty placeholder
  const [typing, setTyping] = useState(false);
  const [fade, setFade] = useState(false);
  const [translatedTexts, setTranslatedTexts] = useState(null); // Set initially to null

  // Fetch translations from Firestore based on the selected language
  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        const docRef = doc(db, 'translations', 'HeroSection');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const translationData = JSON.parse(data[language]); // Parse the string to JSON
          setTranslatedTexts(translationData); // Update the state with parsed data
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      }
    };

    fetchTranslations();
  }, [language]);

  useEffect(() => {
    if (translatedTexts) {
      let index = 0; // Start with the first placeholder
      const placeholders = translatedTexts.placeholders;

      const interval = setInterval(() => {
        if (!typing) {
          setFade(true); // Start fade-out transition
          setTimeout(() => {
            setPlaceholder(placeholders[index]);
            index = (index + 1) % placeholders.length;
            setFade(false); // Start fade-in transition
          }, 500); // Duration of the fade-out effect
        }
      }, 3000); // Change every 3 seconds

      return () => clearInterval(interval);
    }
  }, [typing, translatedTexts]);

  const handleSearch = async (e) => {
    const searchQuery = e.target.value.toLowerCase(); // Convert the search query to lowercase
    setSearchTerm(searchQuery);
    setTyping(true);

    if (searchQuery.length > 2) { // Trigger search only if the user has typed more than 2 characters
      const coursesRef = collection(db, "courses");
      const querySnapshot = await getDocs(coursesRef);
      const searchResults = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (
          data.Title.toLowerCase().includes(searchQuery) || // Convert Title to lowercase
          data.ShortDescription.toLowerCase().includes(searchQuery) || // Convert ShortDescription to lowercase
          data.LongDescription.toLowerCase().includes(searchQuery) // Convert LongDescription to lowercase
        ) {
          searchResults.push({ id: doc.id, ...data }); // Include the document ID
        }
      });

      setSuggestions(searchResults);
    } else {
      setSuggestions([]); // Clear suggestions if search term is too short or empty
    }
  };

  return (
    <div className="hero-section">
      <Container>
        <Row className="justify-content-center text-center">
          <Col lg={8}>
            {translatedTexts && (
              <>
                <h1 className="hero-title">{translatedTexts.title1}</h1>
                <h1 className="hero-title">{translatedTexts.title2}</h1>
                <p className="hero-subtitle">{translatedTexts.subtitle}</p>
              </>
            )}
            
            {/* Custom Search Bar */}
            <div className="search-bar-container">
              <input
                type="text"
                className={`form-control search-bar-input ${fade ? 'fade-placeholder' : ''}`}
                placeholder={!typing ? placeholder : ''}
                value={searchTerm}
                onChange={handleSearch}
                onFocus={() => setTyping(true)}
                onBlur={() => setTyping(searchTerm.length > 0)}
              />
              <span className="search-bar-icon">
                <i className="bi bi-search"></i>
              </span>

              {/* Suggestions Dropdown */}
              {suggestions.length > 0 && searchTerm.length > 2 && (
                <div className="suggestions-container">
                  <h5 className="suggestions-title">Search results</h5>
                  <ul className="suggestions-list">
                    {suggestions.map((suggestion, index) => (
                      <li key={index} className="suggestion-item">
                        <Link to={`/course-details/${suggestion.id}`}>
                          {suggestion.Title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeroSection;
