import React, { useState, useEffect } from 'react';
import './Register.css';
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, sendEmailVerification } from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import ReCAPTCHA from 'react-google-recaptcha'; // Import reCAPTCHA

const Register = ({ language }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false); 
  const [error, setError] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState(null); // State to store reCAPTCHA token
  const [translatedTexts, setTranslatedTexts] = useState(null); // Initialize to null
  const navigate = useNavigate();

  // Fetch translations from Firestore based on the selected language
  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        const docRef = doc(db, 'translations', 'Register');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const translationData = JSON.parse(data[language]); // Parse the string to JSON
          setTranslatedTexts(translationData); // Update state with the parsed data
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      }
    };

    fetchTranslations();
  }, [language]);

  const handleRegister = async (e) => {
    e.preventDefault();

    if (password.length < 8) {
      setError(translatedTexts.passwordTooShort);
      return;
    }

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(email)) {
      setError(translatedTexts.invalidEmail);
      return;
    }

    if (!recaptchaToken) {
      setError(translatedTexts.captchaRequired);
      return;
    }

    setLoading(true); 

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await setDoc(doc(db, 'students', user.uid), {
        name: name,
        email: email,
        Role: "Student",
      });

      await signInWithEmailAndPassword(auth, email, password);
      await sendEmailVerification(user);

      navigate('/');
    } catch (error) {
      setError(translatedTexts.registrationFailed);
      console.error('Error registering:', error);
    } finally {
      setLoading(false); 
    }
  };

  const handleGoogleSignIn = async () => {
    setLoading(true); 
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const userDoc = doc(db, 'students', user.uid);
      const docSnapshot = await getDoc(userDoc);

      if (!docSnapshot.exists()) {
        await setDoc(doc(db, 'students', user.uid), {
          name: user.displayName,
          email: user.email,
          Role: "Student"
        });
      }

      navigate('/');
    } catch (error) {
      setError(translatedTexts.googleSignInFailed);
      console.error('Error signing in with Google:', error);
    } finally {
      setLoading(false); 
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  return (
    <div className="register-page">
      <Container>
        <Row>
          <Col md={6} className="image-section">
            <img src="/images/about_2.png" alt="Register" className="img-fluid register-image" />
          </Col>

          <Col md={6} className="form-section">
            <div className="form-container">
              {translatedTexts && (
                <>
                  <p className="info-text">
                    {translatedTexts.createAccount}
                  </p>
                  {error && <p className="text-danger">{error}</p>}
                  <Form onSubmit={handleRegister}>
                    <Form.Group controlId="formName" className="mb-3">
                      <Form.Label>{translatedTexts.name}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={translatedTexts.enterName}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formEmail" className="mb-3">
                      <Form.Label>{translatedTexts.email}</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder={translatedTexts.enterEmail}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formPassword" className="mb-3">
                      <Form.Label>{translatedTexts.password}</Form.Label>
                      <div className="password-container">
                        <Form.Control
                          type={showPassword ? 'text' : 'password'}
                          placeholder={translatedTexts.enterPassword}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        <i
                          className={`bi ${showPassword ? 'bi-eye' : 'bi-eye-slash'} password-icon`}
                          onClick={togglePasswordVisibility}
                          style={{ cursor: 'pointer' }}
                        ></i>
                      </div>
                    </Form.Group>
                    
                    <ReCAPTCHA
                      sitekey="6LfWuWkqAAAAAEfTpAqzVWKGxBGSl56a4kQE09oU" 
                      onChange={onRecaptchaChange}
                    />

                    <Button variant="primary" type="submit" className="submit-btn" disabled={loading}>
                      {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : translatedTexts.register}
                    </Button>
                  </Form>

                  <div className="social-login-section">
                    <p className="info-text">
                      {translatedTexts.signUpWith}
                    </p>
                    <div className="social-buttons">
                      <Button
                        variant="outline-danger"
                        className="social-btn"
                        onClick={handleGoogleSignIn}
                        disabled={loading}
                      >
                        {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <><i className="bi bi-google"></i> {translatedTexts.google}</>}
                      </Button>
                    </div>
                  </div>

                  <p className="text-center mt-4">
                    {translatedTexts.alreadyHaveAccount} <Link to="/login">{translatedTexts.login}</Link>
                  </p>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Register;
