import React, { useState, useEffect, useCallback } from 'react';
import './MySessions.css';
import { Container, Row, Col, Card, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { auth, db } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { format } from 'date-fns';
import translateText from '../utils/translate';

const MySessions = ({ language }) => {
  const [sessions, setSessions] = useState([]);
  const [translatedTexts, setTranslatedTexts] = useState({
    mySessions: 'My Sessions',
    noSessions: "You haven't booked any sessions yet.",
    timeslot: 'Timeslot:',
    notes: 'Notes:',
    coachNotes: 'Coach Notes:',
    consultationFee: 'Consultation Fee:',
    payFee: 'Pay Fee',
    paid: 'Paid',
    pending: 'Pending',
    approved: 'Approved',
    joinZoomMeeting: 'Join Zoom Meeting',
    joinGoogleMeet: 'Join Google Meet',
    joinMeeting: 'Join Meeting',
    payToContinue: 'You need to pay to continue',
    joinSession: 'Join the session',
    paymentPending: 'When you process the payment, it will usually take a few hours to update your status. You can always check here for the status. Once verified, you will be able to join the link.',
  });

  const user = auth.currentUser;

  const translateSessionTexts = useCallback(async () => {
    const newTranslatedTexts = {};
    for (const [key, value] of Object.entries(translatedTexts)) {
      newTranslatedTexts[key] = await translateText(value, language);
    }
    setTranslatedTexts(newTranslatedTexts);
  }, [language, translatedTexts]);

  useEffect(() => {
    const fetchSessions = async () => {
      if (user) {
        const q = query(
          collection(db, 'bookings'),
          where('UserId', '==', user.uid)
        );

        const querySnapshot = await getDocs(q);
        const sessionsList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        setSessions(sessionsList);
      }
    };

    fetchSessions();
  }, [user]);

  useEffect(() => {
    translateSessionTexts();
  }, [translateSessionTexts]);

  return (
    <div className="my-sessions-page">
      <Container>
        <Row>
          <Col>
            <h2>{translatedTexts.mySessions}</h2>
            {sessions.length === 0 ? (
              <p>{translatedTexts.noSessions}</p>
            ) : (
              <div className="session-list">
                {sessions.map((session, index) => (
                  <Card className="session-card mb-4" key={index}>
                    <Card.Body>
                      <Row>
                        <Col md={8}>
                          <Card.Title>
                            {translatedTexts.timeslot} {format(new Date(session.Timeslot.seconds * 1000), 'MMMM dd, yyyy hh:mm a z')}
                          </Card.Title>
                          <Card.Text className="mt-2">
                            {translatedTexts.notes} {session.Notes}
                          </Card.Text>
                          {session.CoachNotes && (
                            <Card.Text className="mt-2">
                              {translatedTexts.coachNotes} {session.CoachNotes}
                            </Card.Text>
                          )}
                          {session.Price && (
                            <Card.Text className="mt-2">
                              {translatedTexts.consultationFee} ${session.Price}{' '}
                              {session.PaymentStatus === 'Not Paid' ? (
                                <>
                                  <a
                                    href={session.PaymentLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="pay-fee-link"
                                  >
                                    {translatedTexts.payFee}
                                  </a>
                                  <br />
                                  <small className="text-muted">
                                    {translatedTexts.paymentPending}
                                  </small>
                                </>
                              ) : (
                                <Badge bg="success">{translatedTexts.paid}</Badge>
                              )}
                            </Card.Text>
                          )}
                          <Badge bg={session.Status === 'Approved' ? 'success' : 'warning'}>
                            {session.Status === 'Approved' ? translatedTexts.approved : translatedTexts.pending}
                          </Badge>
                        </Col>
                        <Col md={4} className="text-md-end">
                          {session.Link && (
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                session.PaymentStatus === 'Paid' ? (
                                  <Tooltip id={`tooltip-${index}`}>{translatedTexts.joinSession}</Tooltip>
                                ) : (
                                  <Tooltip id={`tooltip-${index}`}>{translatedTexts.payToContinue}</Tooltip>
                                )
                              }
                            >
                              <span className="d-inline-block">
                                {session.PaymentStatus === 'Paid' ? (
                                  <a
                                    href={session.Link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="mt-2 btn btn-primary"
                                  >
                                    {session.Link.includes('zoom.us') ? translatedTexts.joinZoomMeeting : session.Link.includes('meet.google.com') ? translatedTexts.joinGoogleMeet : translatedTexts.joinMeeting}
                                  </a>
                                ) : (
                                  <button
                                    type="button"
                                    className="mt-2 btn btn-secondary disabled"
                                    style={{ cursor: 'not-allowed', opacity: 0.65 }}
                                  >
                                    {session.Link.includes('zoom.us') ? translatedTexts.joinZoomMeeting : session.Link.includes('meet.google.com') ? translatedTexts.joinGoogleMeet : translatedTexts.joinMeeting}
                                  </button>
                                )}
                              </span>
                            </OverlayTrigger>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                ))}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MySessions;
