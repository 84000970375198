// src/superadmin/pages/Courses.js

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Table } from 'react-bootstrap';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase';

const Courses = () => {
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    const coursesSnapshot = await getDocs(collection(db, 'courses'));
    const coursesList = coursesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setCourses(coursesList);
  };

  const handleAddCourse = () => {
    navigate('/superadmin/add-course');
  };

  const handleDeleteCourse = async (courseId) => {
    await deleteDoc(doc(db, 'courses', courseId));
    fetchCourses();
  };

  return (
    <Container>
      <Row className="my-4">
        <Col>
          <h2>Courses Management</h2>
          <Button onClick={handleAddCourse}>Add New Course</Button>
        </Col>
      </Row>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Title</th>
            <th>Session Type</th>
            <th>Level</th>
            <th>Starting Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {courses.map((course) => (
            <tr key={course.id}>
              <td>{course.Title}</td>
              <td>{course.SessionType}</td>
              <td>{course.Level}</td>
              <td>{course.StartingDate}</td>
              <td>
                <Button variant="danger" onClick={() => handleDeleteCourse(course.id)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default Courses;
