import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Badge, Spinner } from 'react-bootstrap';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { loadStripe } from '@stripe/stripe-js';
import translateText from '../utils/translate';

const stripePromise = loadStripe('pk_test_51JmntWEdGlyXkBRXHuWqXNUc2NfJM6ElPjdLT31CQAHuW0wZss47zJNMSz8YPbc0e03fREJo0vJVZ8tHtOwHRSlG006OHyjWpd');

const CourseDetails = ({ language }) => {
  const { courseId } = useParams(); 
  const navigate = useNavigate();
  const [course, setCourse] = useState(null);
  const [courseContent, setCourseContent] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); 
  const [alreadyEnrolled, setAlreadyEnrolled] = useState(false); 

  const [translatedTexts, setTranslatedTexts] = useState({
    loading: 'Loading...',
    courseDetails: 'Course Details:',
    courseContent: 'Course Content:',
    alreadyEnrolled: 'Already Enrolled',
    enrollNow: 'Enroll Now',
    price: 'Price:',
    schedule: 'Schedule:',
    duration: 'Duration:',
    downloadableResources: 'Downloadable Resources:',
    certificateOfCompletion: 'Certificate of Completion',
    fullTimeAccess: 'Full Time Access',
    accessOnMobileAndWeb: 'Access on Mobile and Web',
    starting: 'Starting:',
  });

  useEffect(() => {
    const translateCourseTexts = async () => {
      try {
        const newTranslatedTexts = {
          loading: await translateText('Loading...', language),
          courseDetails: await translateText('Course Details:', language),
          courseContent: await translateText('Course Content:', language),
          alreadyEnrolled: await translateText('Already Enrolled', language),
          enrollNow: await translateText('Enroll Now', language),
          price: await translateText('Price:', language),
          schedule: await translateText('Schedule:', language),
          duration: await translateText('Duration:', language),
          downloadableResources: await translateText('Downloadable Resources:', language),
          certificateOfCompletion: await translateText('Certificate of Completion', language),
          fullTimeAccess: await translateText('Full Time Access', language),
          accessOnMobileAndWeb: await translateText('Access on Mobile and Web', language),
          starting: await translateText('Starting:', language),
        };
        setTranslatedTexts(newTranslatedTexts);
      } catch (error) {
        console.error('Translation Error:', error);
      }
    };

    translateCourseTexts();
  }, [language]);

  useEffect(() => {
    if (!courseId) {
      setError('Course ID is not available.');
      return;
    }

    const fetchCourseDetails = async () => {
      try {
        const courseDoc = await getDoc(doc(db, 'courses', courseId));
        if (courseDoc.exists()) {
          const data = courseDoc.data();
          setCourse({
            ...data,
            Title: await translateText(data.Title, language),
            ShortDescription: await translateText(data.ShortDescription, language),
            LongDescription: await translateText(data.LongDescription, language),
            SessionType: await translateText(data.SessionType, language),
            Schedule: await translateText(data.Schedule, language),
            Price: data.Price ? `$${data.Price}` : await translateText('Free', language),
          });
        } else {
          console.error('Course not found!');
          setError('Course not found.');
        }
      } catch (error) {
        console.error('Error fetching course details:', error);
        setError('Error fetching course details.');
      }
    };

    const fetchCourseContent = async () => {
      try {
        const q = query(collection(db, 'courseContent'), where('CourseId', '==', courseId));
        const querySnapshot = await getDocs(q);
        const contentList = querySnapshot.docs.map(async (doc) => {
          const data = doc.data();
          return {
            ...data,
            Title: await translateText(data.Title, language),
            Details: await translateText(data.Details, language),
          };
        });

        const resolvedContentList = await Promise.all(contentList);
        setCourseContent(resolvedContentList.sort((a, b) => a.LessonNumber - b.LessonNumber));
      } catch (error) {
        console.error('Error fetching course content:', error);
        setError('Error fetching course content.');
      }
    };

    const checkEnrollment = async () => {
      if (auth.currentUser) {
        const userId = auth.currentUser.uid;
        const enrollmentQuery = query(
          collection(db, 'enrolledcourses'),
          where('CourseId', '==', courseId),
          where('StudentId', '==', userId)
        );
        const enrollmentSnapshot = await getDocs(enrollmentQuery);
        if (!enrollmentSnapshot.empty) {
          setAlreadyEnrolled(true);
        }
      }
    };

    fetchCourseDetails();
    fetchCourseContent();
    checkEnrollment();
  }, [courseId, language]);

  const handleEnrollNow = async () => {
    if (!auth.currentUser) {
      navigate('/login');
      return;
    }

    const userId = auth.currentUser.uid;
    const price = course?.Price || 0;
    const successUrl = `${window.location.origin}/success`;

    setLoading(true); 

    try {
      const response = await fetch(
        'https://us-central1-oasee-study-smart.cloudfunctions.net/createStripeCheckoutSession',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            price,
            courseId,
            successUrl,
            userId,
          }),
        }
      );

      const data = await response.json();

      if (!response.ok || !data.data || !data.data.sessionId) {
        throw new Error('Failed to create Stripe Checkout session');
      }

      const sessionId = data.data.sessionId;

      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        console.error('Error during Stripe checkout:', error);
        setError('Failed to redirect to checkout. Please try again.');
      }
    } catch (error) {
      console.error('Error processing payment:', error);
      setError('Failed to process payment. Please try again.');
    } finally {
      setLoading(false); 
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!course) {
    return <div>{translatedTexts.loading}</div>;
  }

  return (
    <div className="course-details-page">
      <Container>
        <Row>
          <Col md={8}>
            <h1 className="course-title">{course.Title}</h1>
            <p className="course-subtitle">{course.ShortDescription}</p>
            <div className="course-meta">
              <Badge bg="info" className="course-badge">{translatedTexts.starting} {course.StartingDate}</Badge>
              <Badge bg="success" className="course-badge">{course.SessionType}</Badge>
            </div>
            <span className="course-students">{translatedTexts.schedule} {course.Schedule}</span>
            <hr />
            <h4>{translatedTexts.courseDetails}</h4>
            <p>{course.LongDescription}</p>
            <hr />
            <h4>{translatedTexts.courseContent}</h4>
            <ul className="course-content">
              {courseContent.map((content, index) => (
                <li key={index}>
                  <strong>Lesson {content.LessonNumber}: {content.Title}</strong>
                  <p>{content.Details}</p>
                </li>
              ))}
            </ul>
          </Col>
          <Col md={4}>
            <div className="course-sidebar">
              <img src={course.Thumbnail || '/images/about_1.png'} alt="Course" className="img-fluid mb-3" />
              <hr />
              <div className="course-price d-flex justify-content-between align-items-center">
                <h4>{translatedTexts.price} {course.Price}</h4>
                {alreadyEnrolled ? (
                  <Button variant="secondary" disabled>
                    {translatedTexts.alreadyEnrolled}
                  </Button>
                ) : (
                  <Button variant="success" onClick={handleEnrollNow} disabled={loading}>
                    {loading ? (
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    ) : (
                      translatedTexts.enrollNow
                    )}
                  </Button>
                )}
              </div>
              <hr />
              <ul className="course-includes">
                <li>⌚ {translatedTexts.duration} {course.Duration}</li>
                <li>🔽 {translatedTexts.downloadableResources} {course.DownloadableResources} Files</li>
                <li>📑 {translatedTexts.certificateOfCompletion}</li>
                <li>💻 {translatedTexts.fullTimeAccess}</li>
                <li>📲 {translatedTexts.accessOnMobileAndWeb}</li>
              </ul>
            </div>
          </Col>
        </Row>
        {error && <div className="error">{error}</div>}
      </Container>
    </div>
  );
};

export default CourseDetails;
