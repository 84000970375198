import React, { useState, useEffect } from 'react';
import './CategorySection.css';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import translateText from '../utils/translate';

const CategorySection = ({ language }) => {
  const [categories, setCategories] = useState([]);
  const [translatedTitle, setTranslatedTitle] = useState('Browse Our Category');
  const navigate = useNavigate();

  useEffect(() => {
    const cachedCategories = sessionStorage.getItem('categoriesWithCounts');
    const cachedLanguage = sessionStorage.getItem('categoriesLanguage');

    if (cachedCategories && cachedLanguage === language) {
      setCategories(JSON.parse(cachedCategories));
      setTranslatedTitle(sessionStorage.getItem('translatedTitle'));
    } else {
      const fetchCategories = async () => {
        try {
          const categoriesSnapshot = await getDocs(collection(db, 'categories'));
          const categoriesList = [];

          for (const categoryDoc of categoriesSnapshot.docs) {
            const categoryData = categoryDoc.data();
            const categoryUid = categoryDoc.id;

            // Query to count the number of courses for this category
            const coursesSnapshot = await getDocs(
              query(collection(db, 'courses'), where('categoryUid', '==', categoryUid))
            );

            // Add category details and course count to the list
            categoriesList.push({
              uid: categoryUid, 
              name: categoryData.name,
              bgColor: categoryData.bgColor,
              icon: categoryData.icon,
              courses: `${coursesSnapshot.size} Courses`,
            });
          }

          const translatedCategories = await Promise.all(
            categoriesList.map(async (category) => ({
              ...category,
              name: await translateText(category.name, language),
              courses: await translateText(category.courses, language),
            }))
          );

          const newTranslatedTitle = await translateText('Browse Our Category', language);

          setCategories(translatedCategories);
          setTranslatedTitle(newTranslatedTitle);

          sessionStorage.setItem('categoriesWithCounts', JSON.stringify(translatedCategories));
          sessionStorage.setItem('categoriesLanguage', language);
          sessionStorage.setItem('translatedTitle', newTranslatedTitle);
        } catch (error) {
          console.error('Error fetching categories:', error);
        }
      };

      fetchCategories();
    }
  }, [language]);

  const handleCardClick = (category) => {
    sessionStorage.setItem('selectedCategoryUid', category.uid);
    navigate(`/courses?category=${category.uid}`);
  };

  return (
    <div className="category-section" id="Categories">
      <Container>
        <h2 className="category-title">{translatedTitle}</h2>
        <Row className="justify-content-center">
          {categories.map((category, index) => (
            <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
              <Card
                className={`category-card ${category.bgColor}`}
                onClick={() => handleCardClick(category)}
              >
                <Card.Body>
                  <i className={`bi ${category.icon} category-icon`}></i>
                  <Card.Title>{category.name}</Card.Title>
                  <Card.Text>{category.courses}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default CategorySection;
