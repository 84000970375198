import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Booking from './pages/Booking';
import Courses from './pages/Courses';
import CourseDetails from './pages/CourseDetails';
import Login from './pages/Login';
import Register from './pages/Register';
import ProfileSettings from './pages/ProfileSettings';
import Wishlist from './pages/Wishlist';
import MyCourses from './pages/MyCourses';
import MySessions from './pages/MySessions';
import MyCourseLearning from './pages/MyCourseLearning';
import DashboardLayout from './superadmin/pages/DashboardLayout';
import AdminRoute from './superadmin/components/AdminRoute';
import SuccessPage from './pages/SuccessPage';

function App() {
  const location = useLocation();

  const [language, setLanguage] = useState('en');

  const adminRoutes = ['/superadmin'];

  const isAdminRoute = adminRoutes.some((route) => location.pathname.startsWith(route));

  return (
    <div className="App">
      {!isAdminRoute && <Header setLanguage={setLanguage} />}
      <Routes>
        <Route path="/" element={<Home language={language} />} />
        <Route path="/about" element={<About language={language} />} />
        <Route path="/contact" element={<Contact language={language} />} />
        <Route path="/booking" element={<Booking language={language} />} />
        <Route path="/courses" element={<Courses language={language} />} />
        <Route path="/course-details/:courseId" element={<CourseDetails language={language} />} />
        <Route path="/login" element={<Login language={language} />} />
        <Route path="/register" element={<Register language={language} />} />
        <Route path="/profile-settings" element={<ProfileSettings  language={language} />} />
        <Route path="/wishlist" element={<Wishlist language={language} />} />
        <Route path="/my-courses" element={<MyCourses language={language} />} />
        <Route path="/success" element={<SuccessPage language={language} />} />
        <Route path="/mylearning/:courseId" element={<MyCourseLearning language={language} />} />
        <Route path="/my-sessions" element={<MySessions language={language} />} />

        {/* Admin Routes */}
        <Route path="/superadmin/*" element={
          <AdminRoute>
            <DashboardLayout />
          </AdminRoute>
        } />
      </Routes>
      {!isAdminRoute && <Footer language={language}/>}
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
