import React, { useState, useEffect } from 'react';
import './Login.css';
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth, db } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';

const Login = ({ language }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [translatedTexts, setTranslatedTexts] = useState(null); // Initialize to null

  const navigate = useNavigate();

  // Fetch translations from Firestore based on the selected language
  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        const docRef = doc(db, 'translations', 'Login');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const translationData = JSON.parse(data[language]); // Parse the string to JSON
          setTranslatedTexts(translationData); // Update state with the parsed data
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      }
    };

    fetchTranslations();
  }, [language]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const userDocRef = doc(db, "students", user.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const userName = userData.name || user.displayName;

        localStorage.setItem('userName', userName);

        // Redirect based on role
        if (userData.Role === 'Admin') {
          navigate('/superadmin/');
        } else if (userData.Role === 'Student') {
          navigate('/', { state: { userName } });
        } else {
          setError(translatedTexts.accessDenied);
        }
      } else {
        setError(translatedTexts.noUserData);
      }
    } catch (error) {
      setError(translatedTexts.signInFailed);
      console.error('Error logging in:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    setLoading(true);
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const userDocRef = doc(db, 'students', user.uid);
      const docSnapshot = await getDoc(userDocRef);

      if (!docSnapshot.exists()) {
        await setDoc(userDocRef, {
          name: user.displayName,
          email: user.email,
          Role: 'Student' // Set default role as Student for Google Sign-In
        });
      }

      const userData = docSnapshot.exists() ? docSnapshot.data() : { Role: 'Student' };
      const userName = userData.name || user.displayName;

      localStorage.setItem('userName', userName);

      // Redirect based on role
      if (userData.Role === 'Admin') {
        navigate('/superadmin/');
      } else {
        navigate('/', { state: { userName } });
      }
    } catch (error) {
      setError(translatedTexts.googleSignInFailed);
      console.error('Error signing in with Google:', error);
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login-page">
      <Container>
        <Row>
          <Col md={6} className="image-section">
            <img src="/images/about_2.png" alt="Login" className="img-fluid login-image" />
          </Col>

          <Col md={6} className="form-section">
            <div className="form-container">
              {translatedTexts && (
                <>
                  <p className="info-text">{translatedTexts.signIn}</p>
                  {error && <p className="text-danger">{error}</p>}
                  <Form onSubmit={handleLogin}>
                    <Form.Group controlId="formUsername" className="mb-3">
                      <Form.Label>{translatedTexts.email}</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder={translatedTexts.enterEmail}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formPassword" className="mb-3">
                      <Form.Label>{translatedTexts.password}</Form.Label>
                      <div className="password-container">
                        <Form.Control
                          type={showPassword ? 'text' : 'password'}
                          placeholder={translatedTexts.enterPassword}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        <i
                          className={`bi ${showPassword ? 'bi-eye' : 'bi-eye-slash'} password-icon`}
                          onClick={togglePasswordVisibility}
                        ></i>
                      </div>
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-center">
                      <Form.Check type="checkbox" label={translatedTexts.rememberMe} />
                      <Link to="/forgot-password" className="forgot-password-link">
                        {translatedTexts.forgotPassword}
                      </Link>
                    </div>
                    <Button variant="primary" type="submit" className="submit-btn" disabled={loading}>
                      {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : translatedTexts.login}
                    </Button>
                  </Form>

                  <div className="social-login-section">
                    <p className="info-text">{translatedTexts.orSignInWith}</p>
                    <div className="social-buttons">
                      <Button
                        variant="outline-danger"
                        className="social-btn"
                        onClick={handleGoogleSignIn}
                        disabled={loading}
                      >
                        {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <><i className="bi bi-google"></i> {translatedTexts.google}</>}
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
