import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Sidebar.css';
import { auth } from '../../firebase'; // Adjust the path to your firebase config
import 'bootstrap-icons/font/bootstrap-icons.css'; // Import Bootstrap Icons

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isContentManagementExpanded, setContentManagementExpanded] = useState(false);

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await auth.signOut();
      navigate('/');
      window.location.reload();
    } catch (error) {
      console.error('Error logging out:', error);
      alert('Failed to logout. Please try again.');
    }
  };

  const toggleContentManagement = () => {
    setContentManagementExpanded(!isContentManagementExpanded);
  };

  return (
    <div className="sidebar">
      <h2>Admin Dashboard</h2>
      <ul className="sidebar-links">
        <li className={location.pathname === '/superadmin/dashboard' ? 'active' : ''}>
          <Link to="/superadmin/">
            <i className="bi bi-speedometer2"></i> Dashboard
          </Link>
        </li>
        <li className={location.pathname === '/superadmin/categories' ? 'active' : ''}>
          <Link to="/superadmin/categories">
            <i className="bi bi-grid"></i> Categories Management
          </Link>
        </li>
        <li className={location.pathname === '/superadmin/course-management' ? 'active' : ''}>
          <Link to="/superadmin/course-management">
            <i className="bi bi-book"></i> Courses Management
          </Link>
        </li>
        <li className={location.pathname === '/superadmin/consultations' ? 'active' : ''}>
          <Link to="/superadmin/consultations">
            <i className="bi bi-calendar-check"></i> Consultation Bookings
          </Link>
        </li>
        <li className={location.pathname === '/superadmin/users' ? 'active' : ''}>
          <Link to="/superadmin/users">
            <i className="bi bi-people"></i> User Records
          </Link>
        </li>

        {/* Content Management Section */}
        <li>
          <div className="content-management-header" onClick={toggleContentManagement}>
            <i className="bi bi-folder"></i> Content Management
          </div>
          {isContentManagementExpanded && (
            <ul className="content-management-links">

              <li className={location.pathname === '/superadmin/header-content' ? 'active' : ''}>
                <Link to="/superadmin/header-content">
                  <i className="bi bi-file-earmark-font"></i> Header Content
                </Link>
              </li>

              <li className={location.pathname === '/superadmin/footer-content' ? 'active' : ''}>
                <Link to="/superadmin/footer-content">
                  <i className="bi bi-columns"></i> Footer Content
                </Link>
              </li>

              <li className={location.pathname === '/superadmin/hero-content' ? 'active' : ''}>
                <Link to="/superadmin/hero-content">
                  <i className="bi bi-file-earmark-play"></i> Home Page Content
                </Link>
              </li>

              <li className={location.pathname === '/superadmin/port-content' ? 'active' : ''}>
                <Link to="/superadmin/port-content">
                  <i className="bi bi-briefcase"></i> Home Portfolio Section
                </Link>
              </li>

              <li className={location.pathname === '/superadmin/about-content' ? 'active' : ''}>
                <Link to="/superadmin/about-content">
                  <i className="bi bi-file-earmark-text"></i> About Page Content
                </Link>
              </li>

              <li className={location.pathname === '/superadmin/booking-content' ? 'active' : ''}>
                <Link to="/superadmin/booking-content">
                  <i className="bi bi-calendar-event"></i> Booking Page Content
                </Link>
              </li>

              <li className={location.pathname === '/superadmin/contact-content' ? 'active' : ''}>
                <Link to="/superadmin/contact-content">
                  <i className="bi bi-envelope"></i> Contact Page Content
                </Link>
              </li>

              <li className={location.pathname === '/superadmin/login-content' ? 'active' : ''}>
                <Link to="/superadmin/login-content">
                  <i className="bi bi-box-arrow-in-right"></i> Login Page
                </Link>
              </li>

              <li className={location.pathname === '/superadmin/register-content' ? 'active' : ''}>
                <Link to="/superadmin/register-content">
                  <i className="bi bi-pencil-square"></i> Register Page
                </Link>
              </li>
            </ul>
          )}
        </li>

        <li className="logout-link">
          <Link to="/" onClick={handleLogout}>
            <i className="bi bi-box-arrow-right"></i> Logout
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
