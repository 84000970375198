// src/pages/Home.js

import React from 'react';
import HeroSection from '../components/HeroSection';
import CategorySection from '../components/CategorySection';
import PopularCoursesSection from '../components/PopularCoursesSection';
import LeaderboardSection from '../components/LeaderboardSection';
import PortfolioSection from '../components/PortfolioSection';

const Home = ({ language }) => {
  return (
    <div>
      <HeroSection language={language} />
      <CategorySection language={language} />
      <PopularCoursesSection language={language} />
      <LeaderboardSection language={language} />
      <PortfolioSection language={language} />
    </div>
  );
};

export default Home;
